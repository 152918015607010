import { makeStyles, Typography } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    background: theme.palette.green()
  },
  textContainer: {
    maxWidth: 600,
    background: theme.palette.dark('light'),
    borderRadius: 3,
    margin: muiTheme.spacing(2),
    padding: muiTheme.spacing(4),
    textAlign: 'center',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
  },
  text: {
    color: theme.colors.veryLightBlue
  }
}))

export const Mobile: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <Typography variant="h3" className={classes.text}>
          We does not support this device's resolution
        </Typography>
      </div>
    </div>
  )
}
