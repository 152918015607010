import { makeStyles, Switch } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  active: boolean
  toggle: () => void
}

const height = 28

const useStyles = makeStyles((muiTheme) => ({
  root: {
    width: 48,
    height,
    padding: 0
  },
  switchBase: {
    padding: 2,
    transform: 'translateX(20px)',
    '&$checked': {
      transform: 'translateX(0px)',
      color: muiTheme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.green(),
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.green(),
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none'
  },
  track: {
    borderRadius: height / 2,
    border: 'none',
    backgroundColor: '#a1b2ce',
    opacity: 1,
    transition: muiTheme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))

export const SwitchToggle: React.FC<Props> = ({ active, toggle }) => {
  const classes = useStyles()

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={active}
      onChange={toggle}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  )
}
