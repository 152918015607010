import { Box, makeStyles } from '@material-ui/core'
import { Tabs } from '@root/components/tabs'
import { dashboardColors } from '@root/constants/dashboard-colors'
import { DashboardColor } from '@shared/models/dashboard'
import React from 'react'
import { setColor } from '../store/actions'
import { useSaveDialogStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  nextPageContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  colorContainer: {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    cursor: 'pointer',
    transition: 'background 200ms',
    marginBottom: muiTheme.spacing(1.5)
  },
  color: {
    width: 28,
    height: 28
  }
}))

export const Colors: React.FC<{}> = () => {
  const [color] = useSaveDialogStore((state) => [state.color])
  const classes = useStyles()

  return (
    <Box py={2} display="flex" justifyContent="center">
      <Tabs
        tabs={dashboardColors.map((colorCode) => ({
          label: colorCode.color,
          value: colorCode.color
        }))}
        currentTab={color}
        size="mini"
        component={(value, active) => (
          <div
            className={classes.colorContainer}
            style={{
              background: dashboardColors.find(
                (dashboardColor) => dashboardColor.color === value
              )?.code
            }}
          />
        )}
        setTab={(value) => setColor(value as DashboardColor)}
      />
    </Box>
  )
}
