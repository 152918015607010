import {
  Button,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from '@material-ui/core'
import { Delete, KeyboardArrowDown, People, Build } from '@material-ui/icons'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { DropdownDivider } from '@root/components/dropdown/dropdown-divider'
import { unauthenticate } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import { navigate } from 'hookrouter'
import React from 'react'

const useStyles = makeStyles((muiTheme: Theme) => ({
  button: {
    padding: muiTheme.spacing(1),
    margin: muiTheme.spacing(0, -1),
    fontSize: 18,
    textTransform: 'none'
  },
  divider: {
    margin: muiTheme.spacing(1, 0)
  }
}))

export const MenuButton: React.FC<{}> = () => {
  const styles = useStyles()
  const [admin, organization] = useAuthStore((state) => [
    state.me.admin,
    state.me.organization
  ])

  return (
    <>
      <Dropdown
        button={
          <Button className={styles.button} endIcon={<KeyboardArrowDown />}>
            {organization}
          </Button>
        }
      >
        {admin && (
          <>
            <MenuItem onClick={() => navigate('/team/users')}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <Typography variant="inherit">My Team</Typography>
            </MenuItem>
            <DropdownDivider />
          </>
        )}
        <MenuItem onClick={() => navigate('/implementation')}>
          <ListItemIcon>
            <Build />
          </ListItemIcon>
          <Typography variant="inherit">Implementation Guide</Typography>
        </MenuItem>
        <MenuItem onClick={unauthenticate}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Dropdown>
    </>
  )
}
