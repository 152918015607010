import { LibraryDataChartModel } from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import create from 'zustand'
import { ActiveDialog } from './models/active-dialog'

interface LibraryStore {
  viewState: 'fetching' | 'library' | 'chart-builder' | 'realtime-editor'
  dashboards: Array<DashboardModel>
  charts: Array<LibraryDataChartModel>
  selectedChartId: number | undefined
  activeDialog: ActiveDialog
}

export const initialState: LibraryStore = {
  viewState: 'fetching',
  dashboards: [],
  charts: [],
  selectedChartId: undefined,
  activeDialog: 'none'
}

export const [useLibraryStore, libraryStoreApi] = create<LibraryStore>(
  () => initialState
)
