import { Divider, InputBase, makeStyles, Theme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Paper } from '@root/components/paper'
import React from 'react'

interface Props {
  label: string
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    padding: muiTheme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: muiTheme.spacing(1),
    flex: 1,
    fontSize: 18
  },
  iconButton: {
    display: 'flex',
    padding: muiTheme.spacing(0, 2)
  },
  divider: {
    height: 55,
    margin: muiTheme.spacing(0, 0.5)
  }
}))

export const SearchField: React.FC<Props> = ({
  label,
  searchQuery,
  setSearchQuery
}) => {
  const styles = useStyles()

  return (
    <Paper component="form" className={styles.root}>
      <div className={styles.iconButton}>
        <SearchIcon />
      </div>
      <Divider className={styles.divider} orientation="vertical" />
      <InputBase
        className={styles.input}
        placeholder={label}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </Paper>
  )
}
