import { Box, Grid, makeStyles } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'
import { nextTypePage, previousTypePage, setType } from '../store/actions'
import {
  useDashboardIcons,
  useMayNextTypePage,
  useMayPreviousTypePage
} from '../store/selectors'
import { useSaveDialogStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  nextPageContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    cursor: 'pointer',
    transition: 'background 200ms'
  },
  iconSelected: {
    background: theme.palette.blue('lighter')
  },
  icon: {
    width: 28,
    height: 28
  }
}))

export const Icons: React.FC<{}> = () => {
  const [type] = useSaveDialogStore((state) => [state.type])
  const dashboardIcons = useDashboardIcons()
  const mayNextTypePage = useMayNextTypePage()
  const mayPreviousTypePage = useMayPreviousTypePage()
  const classes = useStyles()

  return (
    <Box py={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <IconButton
            icon={<NavigateBefore />}
            medium
            disabled={!mayPreviousTypePage}
            onClick={previousTypePage}
          />
        </Grid>
        {dashboardIcons.map((typeIcon, index) => {
          const Icon = typeIcon.icon
          return (
            <Grid key={index} item>
              <div
                className={clsx(
                  classes.iconContainer,
                  typeIcon.type === type && classes.iconSelected
                )}
                onClick={() => setType(typeIcon.type)}
              >
                <Icon className={classes.icon} />
              </div>
            </Grid>
          )
        })}
        <Grid item className={classes.nextPageContainer}>
          <IconButton
            icon={<NavigateNext />}
            medium
            disabled={!mayNextTypePage}
            onClick={nextTypePage}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
