import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { NavigateBefore } from '@material-ui/icons'
import React from 'react'
import { IconButton } from './icon-button'

interface Props {
  title: string
  tabs?: JSX.Element
  action?: JSX.Element
  goBack?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: muiTheme.spacing(8),
    marginTop: muiTheme.spacing(-2.5),
    marginBottom: muiTheme.spacing(4)
  },
  goBack: {
    marginTop: muiTheme.spacing(-0.75)
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export const PageHeader: React.FC<Props> = ({
  title,
  tabs,
  action,
  goBack
}) => {
  const styles = useStyles()

  return (
    <Grid container>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          {goBack && (
            <Grid item className={styles.goBack}>
              <IconButton
                icon={<NavigateBefore />}
                primary
                medium
                onClick={goBack}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant="h3">{title}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <div className={styles.tabs}>{tabs}</div>
      </Grid>
      <Grid item xs={4}>
        <div className={styles.action}>{action}</div>
      </Grid>
    </Grid>
  )
}
