const paletteCreator = (r: number, g: number, b: number) => (
  opacity: 'regular' | 'light' | 'lighter' | 'lightest' = 'regular'
) => {
  switch (opacity) {
    case 'light':
      return `rgba(${r}, ${g}, ${b}, 0.7)`
    case 'lighter':
      return `rgba(${r}, ${g}, ${b}, 0.4)`
    case 'lightest':
      return `rgba(${r}, ${g}, ${b}, 0.2)`
    case 'regular':
    default:
      return `rgba(${r}, ${g}, ${b}, 1)`
  }
}

export const theme = {
  palette: {
    blue: paletteCreator(0, 105, 255),
    turquiose: paletteCreator(0, 189, 176),
    orange: paletteCreator(240, 116, 0),
    dark: paletteCreator(0, 27, 66),
    red: paletteCreator(240, 47, 0),
    green: paletteCreator(0, 189, 72),
    white: paletteCreator(255, 255, 255)
  },
  paletteOpacity: {
    regular: 1,
    light: 0.7,
    lighter: 0.4,
    lightest: 0.2
  },
  colors: {
    background: '#eef5ff',
    layoutShadow: 'rgba(0, 105, 255, 0.3)',
    layout: '#f0f6ff',
    darkGreen: '#00a840',
    darkBlue: '#005ce0',
    darkRed: '#dc2f00',
    outlinedBlue: '#0056d6',
    darkOutlinedBlue: '#00398f',
    veryLightBlue: '#e4e9f1',
    tableGrey: '#f0f3f8'
  }
}
