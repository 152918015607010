import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { DataTable } from '@root/components/data-table/data-table'
import { Loader } from '@root/components/loader'
import { OutlineButton } from '@root/components/outline-button'
import { Paper } from '@root/components/paper'
import React from 'react'

interface Props {
  loading: boolean
  addButtonLabel: string
  columns: React.ReactChild
  dataRows: React.ReactChild
  addButtonClick: () => void
}

const useStyles = makeStyles((muiTheme) => ({
  root: {
    minHeight: 280
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: muiTheme.spacing(2, 3, 1, 1)
  },
  table: {
    marginTop: muiTheme.spacing(2)
  }
}))

export const TeamTable: React.FC<Props> = ({
  loading,
  addButtonLabel,
  columns,
  dataRows,
  addButtonClick
}) => {
  const styles = useStyles()

  return (
    <Paper component="form" className={styles.root}>
      <div className={styles.addButton}>
        <OutlineButton
          primary
          icon={<Add />}
          iconRight
          onClick={addButtonClick}
        >
          {addButtonLabel}
        </OutlineButton>
      </div>
      {loading && <Loader />}
      <div className={styles.table}>
        {!loading && <DataTable columns={columns} dataRows={dataRows} />}
      </div>
    </Paper>
  )
}
