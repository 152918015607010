import { AppBar, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { Loader } from '@root/components/loader'
import { brand } from '@root/constants/brand'
import { theme } from '@root/constants/theme'
import { authenticateSessionId, getMe } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import clsx from 'clsx'
import { usePath } from 'hookrouter'
import React, { useEffect } from 'react'
import { MenuButton } from './components/menu-button'
import { MenuTabs } from './components/menu-tabs'
import generation from './generation.svg'

const isMobile = () => {
  let check = false
  ;((a) => {
    if (
      // eslint-disable-next-line
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

const getHashParams = () =>
  window.location.hash
    .substr(1)
    .split('&')
    .reduce<Record<string, string>>((params, item) => {
      const [key, value] = item.split('=')

      return { ...params, [key]: value }
    }, {})

const width = 1440
const appBarHeight = 64

const useStyles = makeStyles((muiTheme: Theme) => ({
  mobile: {
    [muiTheme.breakpoints.down('sm')]: {
      minWidth: width + 80
    }
  },
  appBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'none'
  },
  appBarContent: {
    width,
    maxWidth: '100%',
    padding: muiTheme.spacing(0, 5),
    margin: '0 auto'
  },
  wrapper: {
    overflowY: 'hidden',
    minHeight: `calc(100vh - ${appBarHeight}px)`
  },
  container: {
    width: '100%',
    maxWidth: width,
    margin: '0 auto',
    boxShadow: `0 0 100px 0 ${theme.colors.layoutShadow}`,
    borderRadius: 6
  },
  mobileContainer: {
    width,
    maxWidth: 'none'
  },
  title: {
    letterSpacing: -1,
    fontWeight: 900
  },
  menuTabs: {
    display: 'flex',
    justifyContent: 'center'
  },
  menuButton: {
    textAlign: 'right'
  },
  contentBackground: {
    position: 'relative',
    backgroundColor: theme.colors.layout
  },
  content: {
    width: 1145,
    minHeight: `calc(100vh - ${appBarHeight}px)`,
    margin: '0 auto',
    padding: `${muiTheme.spacing(6.25)}px 0`
  }
}))

export const Shell: React.FC<{}> = ({ children }) => {
  const styles = useStyles()
  const authState = useAuthStore((state) => state.authState)
  const mobile = isMobile()
  const path = usePath()

  useEffect(() => {
    const { sessionId } = getHashParams()

    if (sessionId) {
      authenticateSessionId(sessionId)
    } else {
      getMe()
    }
  }, [])

  return (
    <>
      {authState === 'verifying' && <Loader />}
      {authState === 'authenticated' && path === '/implementation' && children}
      {authState === 'authenticated' && path !== '/implementation' && (
        <div className={clsx(mobile && styles.mobile)}>
          <AppBar
            position="static"
            color="transparent"
            className={styles.appBar}
          >
            <div className={styles.appBarContent}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="h3" className={styles.title}>
                    {brand === 'generation' ? (
                      <img src={generation} alt="logo" width={136} />
                    ) : (
                      'fourbee'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.menuTabs}>
                  <MenuTabs />
                </Grid>
                <Grid item xs={3} className={styles.menuButton}>
                  <MenuButton />
                </Grid>
              </Grid>
            </div>
          </AppBar>
          <div className={styles.wrapper}>
            <div
              className={clsx(
                styles.container,
                mobile && styles.mobileContainer
              )}
            >
              <div className={styles.contentBackground}>
                <div className={styles.content}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
