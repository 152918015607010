import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'
import { OutlineButton } from '../outline-button'

interface Props {
  open: boolean
  title: string
  content: JSX.Element
  actions: JSX.Element
  large?: boolean
  onClose: () => void
}

const useStyles = makeStyles((muiTheme) => ({
  root: {
    position: 'relative',
    minWidth: 600,
    overflowY: 'visible'
  },
  large: {
    minWidth: 1146
  },
  padded: {
    padding: muiTheme.spacing(3)
  },
  largePadded: {
    padding: muiTheme.spacing(3, 12)
  },
  sidePadded: {
    padding: muiTheme.spacing(0, 3)
  },
  largeSidePadded: {
    padding: muiTheme.spacing(0, 12)
  }
}))

export const Dialog: React.FC<Props> = ({
  open,
  title,
  content,
  actions,
  large,
  onClose
}) => {
  const classes = useStyles()

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      classes={{ paper: clsx(classes.root, large && classes.large) }}
    >
      <Box position="absolute" right={4} top={-32}>
        <OutlineButton white icon={<Close />} iconRight onClick={onClose}>
          Close
        </OutlineButton>
      </Box>
      <DialogTitle className={large ? classes.largePadded : classes.padded}>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent
        className={large ? classes.largeSidePadded : classes.sidePadded}
      >
        {content}
      </DialogContent>
      <DialogActions className={large ? classes.largePadded : classes.padded}>
        {actions}
      </DialogActions>
    </MuiDialog>
  )
}
