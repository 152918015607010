import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme
} from '@material-ui/core'
import { Check, Delete } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { IconButton } from '@root/components/icon-button'
import { theme } from '@root/constants/theme'
import { FilterComparator } from '@shared/models/charts/filter-comparator'
import clsx from 'clsx'
import React from 'react'
import { OutlineButton } from './outline-button'

interface Props {
  columns: Array<string>
  column: string
  comparator: FilterComparator
  value: string
  padded?: boolean
  setColumn?: (column: string) => void
  setComparator?: (comparator: FilterComparator) => void
  setValue?: (value: string) => void
  save?: () => void
  remove?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex'
  },
  formControl: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: muiTheme.spacing(0.75),
    paddingBottom: muiTheme.spacing(0.75),
    borderBottom: `1px solid ${theme.colors.veryLightBlue}`,
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      border: '0 !important'
    }
  },
  padded: {
    paddingTop: muiTheme.spacing(1.5),
    paddingBottom: muiTheme.spacing(1.5)
  },
  comparator: {
    fontSize: 14,
    margin: muiTheme.spacing(0, 2),
    padding: muiTheme.spacing(0, 2),
    borderLeft: `1px solid ${theme.palette.dark('lightest')}`,
    borderRight: `1px solid ${theme.palette.dark('lightest')}`,
    '& input': {
      fontSize: 18
    }
  },
  autocomplete: {
    flexGrow: 1,
    width: 110
  },
  value: {
    width: 85
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: muiTheme.spacing(2)
  }
}))

export const AdvancedFilter: React.FC<Props> = ({
  columns,
  column,
  comparator,
  value,
  padded,
  setColumn,
  setComparator,
  setValue,
  save,
  remove
}) => {
  const styles = useStyles()

  return (
    <div className={styles.filter}>
      <FormControl
        className={clsx(styles.formControl, padded && styles.padded)}
      >
        <Autocomplete
          options={columns}
          autoHighlight
          autoComplete
          disableClearable
          getOptionLabel={(option) =>
            option.replace(/_|-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())
          }
          renderInput={(params) => <TextField {...params} margin="none" />}
          className={styles.autocomplete}
          onChange={(event: any, newValue: string | null, reason) => {
            if (setColumn) {
              if (newValue) {
                setColumn(newValue)
              } else if (reason === 'clear') {
                setColumn('')
              }
            }
          }}
          value={column}
        />

        <Select
          value={comparator}
          disabled={!setComparator}
          onChange={(e) =>
            setComparator && setComparator(e.target.value as FilterComparator)
          }
          className={styles.comparator}
        >
          <MenuItem value="equals">Equals</MenuItem>
          <MenuItem value="greater-than">Greater than</MenuItem>
          <MenuItem value="less-than">Less than</MenuItem>
        </Select>
        <TextField
          value={value}
          disabled={!setValue}
          onChange={(e) => setValue && setValue(e.target.value)}
          className={styles.value}
        />
      </FormControl>
      <div className={styles.action}>
        {save && (
          <OutlineButton primary icon={<Check />} onClick={save}>
            Save
          </OutlineButton>
        )}
      </div>
      <div className={styles.action}>
        {remove && <IconButton icon={<Delete />} padding onClick={remove} />}
      </div>
    </div>
  )
}
