import {
  createSetDirtyField,
  createSetField,
  createSetPreviousDirtyFields
} from '@root/helpers/store/form'
import { dashboardStoreApi } from '@root/pages/dashboard/store/store'
import { saveReport } from '../../../store/actions'
import { initialState, scheduleReportDialogStoreApi } from './store'

const getSelectedReport = () => {
  const { reports, selectedDialogEntityId } = dashboardStoreApi.getState()

  return reports.find((_report) => _report.id === selectedDialogEntityId)
}

export const init = async () => {
  const report = getSelectedReport()

  if (report) {
    scheduleReportDialogStoreApi.setState({
      id: report.id,
      fields: {
        name: report.name
      },
      dirtyFields: {
        name: true
      },
      schedules: [...report.schedules],
      recipients: [...report.recipients]
    })
  }
}

export const reset = () =>
  scheduleReportDialogStoreApi.setState({ ...initialState })
export const setField = createSetField(scheduleReportDialogStoreApi)
export const setDirtyField = createSetDirtyField(scheduleReportDialogStoreApi)
export const setPreviousDirtyFields = createSetPreviousDirtyFields(
  scheduleReportDialogStoreApi,
  ['name']
)

export const addSchedule = () =>
  scheduleReportDialogStoreApi.setState({
    schedules: [
      ...scheduleReportDialogStoreApi.getState().schedules,
      {
        id: 0,
        interval: 'weekly',
        day: 'monday',
        time: '12:00',
        timeZone: 'utc',
        active: true
      }
    ]
  })

export const removeSchedule = (index: number) =>
  scheduleReportDialogStoreApi.setState({
    schedules: scheduleReportDialogStoreApi
      .getState()
      .schedules.filter((schedule, _index) => _index !== index)
  })

export const updateSchedule = (
  index: number,
  key: 'interval' | 'day' | 'time' | 'timeZone',
  value: string
) =>
  scheduleReportDialogStoreApi.setState({
    schedules: scheduleReportDialogStoreApi
      .getState()
      .schedules.map((schedule, _index) =>
        _index === index ? { ...schedule, [key]: value } : schedule
      )
  })

export const toggleScheduleActive = (index: number) =>
  scheduleReportDialogStoreApi.setState({
    schedules: scheduleReportDialogStoreApi
      .getState()
      .schedules.map((schedule, _index) =>
        _index === index ? { ...schedule, active: !schedule.active } : schedule
      )
  })

export const addRecipient = (recipient: string) =>
  scheduleReportDialogStoreApi.setState({
    recipients: [
      ...scheduleReportDialogStoreApi.getState().recipients,
      recipient
    ]
  })

export const setRecipients = (recipients: Array<string>) =>
  scheduleReportDialogStoreApi.setState({
    recipients: [...recipients]
  })

export const removeRecipient = (recipient: string) =>
  scheduleReportDialogStoreApi.setState({
    recipients: scheduleReportDialogStoreApi
      .getState()
      .recipients.filter((_recipient) => _recipient !== recipient)
  })

export const save = async () => {
  const {
    id,
    fields: { name },
    schedules,
    recipients
  } = scheduleReportDialogStoreApi.getState()

  scheduleReportDialogStoreApi.setState({ saving: true })

  saveReport(id, name, schedules, recipients)
}
