import { Divider, makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    margin: muiTheme.spacing(1, 0)
  }
}))

export const DropdownDivider: React.FC<{}> = () => {
  const styles = useStyles()

  return <Divider className={styles.root} />
}
