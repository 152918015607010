import { makeStyles, TableCell, Theme } from '@material-ui/core'
import React from 'react'

interface Props {
  width?: string
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    border: 0,
    paddingBottom: muiTheme.spacing(0.75)
  }
}))

export const EmptyColumn: React.FC<Props> = ({ width }) => {
  const styles = useStyles()

  return <TableCell width={width} className={styles.root} />
}
