import { makeStyles, Theme } from '@material-ui/core'
import { SchemeType } from '@shared/models/transactions/scheme'
import React from 'react'

interface Props {
  scheme: SchemeType
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 64
  }
}))

const schemes: Array<{ name: SchemeType; icon: string }> = [
  { name: 'visa', icon: '/icons/schemes/Scheme_1_Visa.svg' },
  { name: 'mastercard', icon: '/icons/schemes/Scheme_2_MasterCard.svg' },
  { name: 'amex', icon: '/icons/schemes/Scheme_3_Amex.svg' },
  { name: 'cb', icon: '/icons/schemes/Scheme_4_CB.svg' },
  { name: 'wechatpay', icon: '/icons/schemes/Scheme_5_WeChatPay.svg' },
  { name: 'alipay', icon: '/icons/schemes/Scheme_6_AliPay.svg' },
  { name: 'swish', icon: '/icons/schemes/Scheme_7_Swish.svg' },
  { name: 'klarna', icon: '/icons/schemes/Scheme_8_Klarna.svg' }
]

export const Scheme: React.FC<Props> = ({ scheme }) => {
  const classes = useStyles()
  const schemeIcon = schemes.find((_scheme) => _scheme.name === scheme)

  return (
    <div className={classes.root}>
      <img className={classes.icon} alt="scheme" src={schemeIcon?.icon} />
    </div>
  )
}
