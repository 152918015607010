import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  columns: React.ReactChild
  dataRows: React.ReactChild
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    position: 'relative',
    padding: muiTheme.spacing(0, 1)
  },
  tableContainer: {
    paddingBottom: muiTheme.spacing(0.75),
    boxShadow: 'none',
    overflowX: 'auto'
  },
  tableHead: {
    before: 'relative',
    '&::after': {
      content: '" "',
      position: 'absolute',
      left: 0,
      width: '100%',
      borderTop: `1px solid ${theme.colors.veryLightBlue}`
    }
  },
  cell: {
    fontSize: 16,
    color: theme.palette.dark('light'),
    border: 0
  }
}))

export const DataTable: React.FC<Props> = ({ columns, dataRows }) => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table size="small" aria-label="a dense table">
          <TableHead className={styles.tableHead}>
            <TableRow>{columns}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={styles.cell} />
            </TableRow>
            {dataRows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
