import { makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React, { cloneElement } from 'react'
import { DropdownMenu } from './dropdown-menu'

interface Props {
  staticHeight?: boolean
  button: JSX.Element
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  menu: {
    minWidth: 200,
    fontSize: 16,
    color: theme.palette.dark(),
    '& span, & svg': {
      opacity: 0.7
    }
  }
}))

export const Dropdown: React.FC<Props> = ({
  staticHeight,
  button,
  children
}) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {cloneElement(button, { ...button.props, onClick: handleClick })}
      <DropdownMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        classes={{ paper: styles.menu }}
        PaperProps={{
          style: {
            maxHeight: staticHeight ? 200 : 'unset'
          }
        }}
      >
        {children}
      </DropdownMenu>
    </>
  )
}
