import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { ChartSelection } from '../components/chart-selection'
import { setChartType } from '../store/actions'
import { useAvailableChartTypes } from '../store/selectors'
import { useChartBuilderStore } from '../store/store'

const useStyles = makeStyles((muiTheme: Theme) => ({
  title: {
    letterSpacing: '0.81px',
    textTransform: 'uppercase',
    fontWeight: 900,
    marginBottom: muiTheme.spacing(6)
  }
}))

export const SelectChart: React.FC<{}> = () => {
  const styles = useStyles()
  const [typeId] = useChartBuilderStore((state) => [state.typeId])
  const types = useAvailableChartTypes()

  return (
    <>
      <Typography variant="body1" className={styles.title}>
        select chart
      </Typography>
      <Grid container spacing={6}>
        {types.map(({ id, name, image, variant }, index) => (
          <Grid key={index} item xs={3}>
            <ChartSelection
              variant="type"
              image={image}
              title={name}
              selected={typeId === id}
              click={() => setChartType(id)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
