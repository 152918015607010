import { ClientEndpointBaseCollection } from '@shared/transport/http/endpoints'

export const setupEndpoints = (
  endpointCollection: ClientEndpointBaseCollection,
  route = ''
) => {
  for (const identifier in endpointCollection) {
    const collection = endpointCollection[identifier]
    const nextRoute = `${route}/${identifier}`

    if (typeof collection.dispatch === 'function') {
      Reflect.defineMetadata('route', nextRoute, collection)
    } else {
      setupEndpoints(collection as ClientEndpointBaseCollection, nextRoute)
    }
  }
}
