import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  Theme
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { OutlineButton } from '@root/components/outline-button'
import React from 'react'
import { toggleVisibleColumn } from '../store/actions'
import { useColumns } from '../store/selectors'

const useStyles = makeStyles((muiTheme: Theme) => ({
  listItemText: {
    fontSize: 16
  }
}))

export const ShowHideColumns = () => {
  const styles = useStyles()
  const columns = useColumns()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <OutlineButton
        primary
        iconRight
        icon={<ExpandMore />}
        onClick={(e) => handleClick(e)}
      >
        Show / Hide columns
      </OutlineButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List dense>
          {columns.map((column, index) => (
            <ListItem
              key={index}
              button
              onClick={() => toggleVisibleColumn(column.key)}
            >
              <ListItemIcon>
                <Checkbox checked={column.visible} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: styles.listItemText }}
                primary={column.label}
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </>
  )
}
