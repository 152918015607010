import {
  Box,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from '@material-ui/core'
import {
  Add,
  Delete,
  Edit,
  Equalizer,
  FileCopy,
  Menu,
  MoreHoriz,
  Remove,
  WatchLater
} from '@material-ui/icons'
import { Paper } from '@root/components/paper'
import clsx from 'clsx'
import React from 'react'
import { Dropdown } from '../dropdown/dropdown'
import { DropdownDivider } from '../dropdown/dropdown-divider'
import { IconButton } from '../icon-button'

interface Props {
  name: string
  subtitle?: string
  duplicate?: () => void
  schedule?: () => void
  addToDashboard?: () => void
  rename?: () => void
  edit?: () => void
  realtimeEdit?: () => void
  remove?: () => void
  removeFromDashboard?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  chart: {
    position: 'relative',
    height: '100%',
    padding: muiTheme.spacing(2.5, 3.75)
  },
  chartTitle: {
    fontWeight: 'bold',
    marginBottom: muiTheme.spacing(0.5)
  },
  chartTitleMargin: {
    marginBottom: muiTheme.spacing(2.5)
  },
  chartSubTitle: {
    fontWeight: 'lighter',
    fontSize: 16,
    fontStyle: 'italic',
    marginBottom: muiTheme.spacing(2.5)
  }
}))

export const BigChart: React.FC<Props> = ({
  name,
  subtitle,
  children,
  duplicate,
  schedule,
  addToDashboard,
  rename,
  edit,
  realtimeEdit,
  remove,
  removeFromDashboard
}) => {
  const styles = useStyles()

  if (!edit) {
    return <>{children}</>
  }

  return (
    <Paper rounded className={styles.chart}>
      <Box position="absolute" mt={1} mr={1.75} top={0} right={0}>
        <Dropdown button={<IconButton icon={<MoreHoriz />} padding />}>
          {duplicate && (
            <MenuItem onClick={duplicate}>
              <ListItemIcon>
                <FileCopy />
              </ListItemIcon>
              <Typography variant="inherit">Duplicate</Typography>
            </MenuItem>
          )}
          {schedule && (
            <MenuItem onClick={schedule}>
              <ListItemIcon>
                <WatchLater />
              </ListItemIcon>
              <Typography variant="inherit">Schedule</Typography>
            </MenuItem>
          )}
          {addToDashboard && (
            <MenuItem onClick={addToDashboard}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <Typography variant="inherit">Add to dashboard</Typography>
            </MenuItem>
          )}
          <DropdownDivider />
          <MenuItem onClick={rename}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <Typography variant="inherit">Rename chart</Typography>
          </MenuItem>
          <MenuItem onClick={edit}>
            <ListItemIcon>
              <Menu />
            </ListItemIcon>
            <Typography variant="inherit">Edit chart</Typography>
          </MenuItem>
          {realtimeEdit && (
            <MenuItem onClick={realtimeEdit}>
              <ListItemIcon>
                <Equalizer />
              </ListItemIcon>
              <Typography variant="inherit">Realtime Edit</Typography>
            </MenuItem>
          )}
          <DropdownDivider />
          {remove && (
            <MenuItem onClick={remove}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <Typography variant="inherit">Remove</Typography>
            </MenuItem>
          )}
          {removeFromDashboard && (
            <MenuItem onClick={removeFromDashboard}>
              <ListItemIcon>
                <Remove />
              </ListItemIcon>
              <Typography variant="inherit">Remove from dashboard</Typography>
            </MenuItem>
          )}
        </Dropdown>
      </Box>
      <Typography
        variant="subtitle2"
        className={clsx(
          styles.chartTitle,
          !subtitle && styles.chartTitleMargin
        )}
      >
        {name}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle2" className={styles.chartSubTitle}>
          {subtitle}
        </Typography>
      )}
      {children}
    </Paper>
  )
}
