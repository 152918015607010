import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '80px 0'
  }
}))

export const Loader = () => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <CircularProgress size={50} />
    </div>
  )
}
