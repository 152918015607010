import { DashboardColor, DashboardType } from '@shared/models/dashboard'
import { dashboardStoreApi } from '../../../store/store'
import { initialState, saveDialogStoreApi } from './store'

const { getState, setState } = saveDialogStoreApi

export const reset = (id?: number) => {
  let stateUpdate = { ...initialState }

  if (id) {
    const dashboard = dashboardStoreApi
      .getState()
      .dashboards.find((_dashboard) => _dashboard.id === id)

    if (dashboard) {
      stateUpdate = {
        ...stateUpdate,
        id,
        name: dashboard.label,
        description: dashboard.description,
        type: dashboard.type,
        color: dashboard.color
      }
    }
  }
  setState({ ...stateUpdate })
}

export const setName = (name: string) => setState({ name })
export const setDescription = (description: string) => setState({ description })
export const setType = (type: DashboardType) => setState({ type })
export const setColor = (color: DashboardColor) => setState({ color })
export const previousTypePage = () =>
  setState({ typePage: getState().typePage - 1 })

export const nextTypePage = () =>
  setState({ typePage: getState().typePage + 1 })

export const gotoLayout = () => setState({ view: 'layout' })
