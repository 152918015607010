import { Button as MuiButton, makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    padding: muiTheme.spacing(1.25, 3),
    color: theme.palette.red(),
    border: `2px solid ${theme.palette.red()}`,
    borderRadius: 3,
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 'bold',
    '& .MuiButton-endIcon > *:first-child': {
      fontSize: 24
    }
  },
  disabled: {
    border: `2px solid ${theme.palette.dark('lightest')}`
  }
}))

export const ErrorButton: React.FC<Props> = ({
  disabled,
  onClick,
  children
}) => {
  const styles = useStyles()
  return (
    <MuiButton
      variant="text"
      disableRipple
      disabled={disabled}
      className={clsx(styles.root, disabled && styles.disabled)}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  )
}
