import { getValidationErrors, validate } from '@root/helpers/validation'
import { userRules } from '@shared/helpers/validation'
import { MerchantModel } from '@shared/models/user/merchant'
import Validator from 'validatorjs'
import { useUserStore } from '../../../store/store'
import { FieldsModel } from '../models/fields'
import { userDialogStoreApi, useUserDialogStore } from './store'

Validator.register(
  'userDialog.uniqueEmail',
  () => userDialogStoreApi.getState().emailState !== 'taken',
  'The email is already taken.'
)

const rules = {
  ...userRules,
  email: `${userRules.email}|userDialog.uniqueEmail`
}

export const useErrors = (): Record<keyof FieldsModel, string> => {
  const [fields, dirtyFields] = useUserDialogStore((state) => [
    state.fields,
    state.dirtyFields
  ])

  return getValidationErrors(fields, dirtyFields, rules)
}

export const useCanSetStatus = () => {
  const selectedUserId = useUserStore((state) => state.selectedUserId)
  const saving = useUserDialogStore((state) => state.saving)

  return selectedUserId && !saving
}

export const useCanSave = () => {
  const [saving, emailState, fields] = useUserDialogStore((state) => [
    state.saving,
    state.emailState,
    state.fields
  ])

  return !saving && emailState !== 'validating' && validate(fields, rules)
}

export const useOpen = () => {
  const activeDialog = useUserStore((state) => state.activeDialog)

  return activeDialog === 'edit'
}

export const useAvailableRoles = () => {
  const [organizationRoles, roles] = useUserDialogStore((state) => [
    state.organizationRoles,
    state.roles
  ])

  return organizationRoles.filter(
    (organizationRole) => !roles.find((role) => organizationRole.id === role.id)
  )
}

export const useMerchants = (): Array<MerchantModel> => {
  const organizationMerchants = useUserStore((state) => state.merchants)
  const merchants = useUserDialogStore((state) => state.merchants)

  return merchants.map(
    (merchant) =>
      organizationMerchants.find(
        (organizationMerchant) => organizationMerchant.id === merchant
      )!
  )
}

export const useAvailableMerchants = () => {
  const organizationMerchants = useUserStore((state) => state.merchants)
  const merchants = useUserDialogStore((state) => state.merchants)

  return organizationMerchants.filter(
    (organizationMerchant) =>
      !merchants.find((merchant) => organizationMerchant.id === merchant)
  )
}
