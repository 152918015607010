import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { GridOn } from '@material-ui/icons'
import { ChartRenderer } from '@root/components/charts/chart-renderer'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import { getCsvProps } from '@root/helpers/csv'
import { PercentageData, TimelineData } from '@shared/models/charts/any-chart'
import React from 'react'
import { setRenderError } from '../store/actions'
import { useChart } from '../store/selectors'
import { useRealtimeEditorStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    padding: muiTheme.spacing(2.5, 3.75)
  },
  excelBox: {
    textDecoration: 'none',
    '&:hover': {
      background: theme.colors.veryLightBlue,
      cursor: 'pointer'
    }
  },
  text: {
    color: theme.palette.dark('lighter')
  },
  dashboards: {
    minWidth: 250
  }
}))

// TODO: Refine
const dataToRows = (data: PercentageData | TimelineData): Array<Array<any>> => {
  const timelineData = data as TimelineData
  if (timelineData.labels) {
    return [
      ['Date', ...timelineData.labels.map((label) => label.name)],
      ...timelineData.dataList.map((row) => [
        row.date,
        ...timelineData.labels.map((label) => row[label.key])
      ])
    ]
  }

  const percentageData = data as PercentageData
  return percentageData.dataList.map((row) => [row.label, row.count])
}

export const ChartFinalized: React.FC = () => {
  const classes = useStyles()
  const [chartRenderError, data] = useRealtimeEditorStore((store) => [
    store.chartRenderError,
    store.data
  ])
  const chart = useChart()

  return (
    <Paper rounded className={classes.root}>
      {!chart.data && <p>Retrieving data ...</p>}
      {chart.data && (
        <Grid container justify="center">
          {chart && !chartRenderError && (
            <ChartRenderer chart={chart} error={setRenderError} />
          )}
          {chart && chartRenderError && (
            <Box
              component="a"
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={2}
              borderRadius={4}
              className={classes.excelBox}
              {...getCsvProps(dataToRows(data), 'advanced_custom_report.csv')}
            >
              <GridOn
                style={{
                  fontSize: 120,
                  color: theme.palette.green(),
                  marginBottom: 24
                }}
              />
              <Typography variant="h3" className={classes.text}>
                Advanced Custom Report
              </Typography>
              <Typography variant="subtitle2" className={classes.text}>
                Click here to continue in Excel!
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Paper>
  )
}
