import { Grid, Theme, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '@root/constants/theme'
import { Button } from '@root/components/button'
import { OutlineButton } from '@root/components/outline-button'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@root/components/icon-button'
import { navigate } from 'hookrouter'
import { brand } from '@root/constants/brand'
import { Switch, Case } from 'react-if'
import generation from '../../layout/components/shell/generation.svg'
import { Synchronize } from './components/synchronize'
import { Steps } from './components/steps'
import { useImplementationGuideStore } from './store/store'
import {
  fetchSynchronizeTables,
  nextStep,
  previousStep,
  reset
} from './store/actions'
import { Access } from './components/access'
import { Accounts } from './components/accounts'
import { Ready } from './components/ready'

const width = 1280

const useStyles = makeStyles((muiTheme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: width,
    margin: `${muiTheme.spacing(4)}px auto`,
    boxShadow: `0 0 100px 0 ${theme.colors.layoutShadow}`,
    borderRadius: 6
  },
  title: {
    letterSpacing: -1,
    fontWeight: 900
  },
  fourbeeTitle: {
    color: theme.palette.blue('light')
  },
  contentBackground: {
    position: 'relative',
    backgroundColor: theme.colors.layout
  },
  content: {
    maxHeight: 'calc(100vh - 64px)',
    padding: `${muiTheme.spacing(5)}px`
  }
}))

export const ImplementationGuide: React.FC<{}> = () => {
  const styles = useStyles()
  const step = useImplementationGuideStore((state) => state.step)

  useEffect(() => {
    fetchSynchronizeTables()

    return reset
  }, [])

  return (
    <div className={clsx(styles.container)}>
      <div className={styles.contentBackground}>
        <div className={styles.content}>
          <Box position="absolute" top={16} right={16}>
            <IconButton
              small
              secondary
              icon={<CloseIcon />}
              onClick={() => navigate('/')}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                variant="h3"
                className={clsx(styles.title, styles.fourbeeTitle)}
              >
                {brand === 'generation' ? (
                  <img src={generation} alt="logo" width={136} />
                ) : (
                  'fourbee'
                )}
              </Typography>
              <Typography variant="h2" className={styles.title}>
                Implementation Guide
              </Typography>
            </Grid>
            <Grid item xs>
              <Steps />
            </Grid>
          </Grid>
          <Box
            my={2}
            pb={2}
            pr={2}
            maxHeight="calc(100vh - 320px)"
            overflow="hidden auto"
          >
            <Switch>
              <Case condition={step === 'access'}>
                <Access />
              </Case>
              <Case condition={step === 'synchronize'}>
                <Synchronize />
              </Case>
              <Case condition={step === 'accounts'}>
                <Accounts />
              </Case>
              <Case condition={step === 'ready'}>
                <Ready />
              </Case>
            </Switch>
          </Box>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <OutlineButton
                primary
                disabled={step === 'access'}
                onClick={previousStep}
              >
                Previous
              </OutlineButton>
            </Grid>
            <Grid item>
              <Button primary disabled={step === 'ready'} onClick={nextStep}>
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
