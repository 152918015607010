import {
  CircularProgress,
  Grid,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from '@material-ui/core'
import {
  Delete,
  Edit,
  FileCopy,
  Remove,
  Menu,
  MoreHoriz,
  ThumbUp,
  WatchLater
} from '@material-ui/icons'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Dropdown } from '../dropdown/dropdown'
import { DropdownDivider } from '../dropdown/dropdown-divider'
import { IconButton } from '../icon-button'

interface Props {
  variant: 'positive' | 'negative'
  title: string
  value: string
  pitch: string
  benchmark: number
  duplicate?: () => void
  schedule?: () => void
  rename?: () => void
  edit?: () => void
  remove?: () => void
  removeFromDashboard?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    position: 'relative',
    padding: muiTheme.spacing(4),
    height: '100%'
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  title: {
    color: theme.palette.dark('light')
  },
  value: {
    color: theme.palette.green()
  },
  valueNegative: {
    color: theme.palette.red()
  },
  benchmark: {
    // display: 'flex',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center'
  },
  benchmarkIndicator: {
    position: 'relative',
    width: 55,
    height: 55,
    marginBottom: 10
  },
  benchmarkIndicatorBase: {
    position: 'absolute',
    top: 5,
    left: 5
  },
  benchmarkIndicatorActual: {
    position: 'absolute'
  },
  benchmarkIndicatorNegative: {
    color: theme.palette.red()
  },
  benchmarkIcon: {
    position: 'absolute',
    top: '18px',
    left: '18px'
  },
  benchmarkLabel: {
    color: theme.palette.dark('lighter'),
    fontWeight: 'bold'
  },
  actions: {
    position: 'absolute',
    top: 8,
    right: 14
  }
}))

export const SmallChart: React.FC<Props> = ({
  variant,
  title,
  value,
  pitch,
  benchmark,
  duplicate,
  schedule,
  rename,
  edit,
  remove,
  removeFromDashboard
}) => {
  const styles = useStyles()
  const [benchmarkPercent, setBenchmarkState] = useState(0)

  useEffect(() => {
    setTimeout(() => setBenchmarkState(benchmark), 250)
  }, [benchmark])

  return (
    <Paper rounded className={styles.root}>
      <div className={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className={styles.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              className={clsx(
                styles.value,
                variant === 'negative' && styles.valueNegative
              )}
            >
              {value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" className={styles.title}>
              {pitch}
            </Typography>
          </Grid>
        </Grid>
        <div className={styles.benchmark}>
          <div className={styles.benchmarkIndicator}>
            <CircularProgress
              variant="static"
              size={45}
              thickness={2}
              value={100}
              className={clsx(
                styles.benchmarkIndicatorBase,
                variant === 'negative' && styles.benchmarkIndicatorNegative
              )}
            />
            <CircularProgress
              variant="static"
              size={55}
              thickness={6}
              value={benchmarkPercent}
              className={clsx(
                styles.benchmarkIndicatorActual,
                variant === 'negative' && styles.benchmarkIndicatorNegative
              )}
            />
            <ThumbUp
              fontSize="small"
              color={variant === 'positive' ? 'primary' : 'error'}
              className={styles.benchmarkIcon}
            />
          </div>
          <Typography variant="h4" className={styles.title}>
            {benchmark}%
          </Typography>
          <Typography variant="body2" className={styles.benchmarkLabel}>
            Benchmark
          </Typography>
        </div>
        {edit && (
          <div className={styles.actions}>
            <Dropdown button={<IconButton icon={<MoreHoriz />} padding />}>
              {duplicate && (
                <MenuItem onClick={duplicate}>
                  <ListItemIcon>
                    <FileCopy />
                  </ListItemIcon>
                  <Typography variant="inherit">Duplicate</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={schedule}>
                <ListItemIcon>
                  <WatchLater />
                </ListItemIcon>
                <Typography variant="inherit">Schedule</Typography>
              </MenuItem>
              <DropdownDivider />
              <MenuItem onClick={rename}>
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <Typography variant="inherit">Rename chart</Typography>
              </MenuItem>
              <MenuItem onClick={edit}>
                <ListItemIcon>
                  <Menu />
                </ListItemIcon>
                <Typography variant="inherit">Edit chart</Typography>
              </MenuItem>
              <DropdownDivider />
              {remove && (
                <MenuItem onClick={remove}>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <Typography variant="inherit">Remove</Typography>
                </MenuItem>
              )}
              {removeFromDashboard && (
                <MenuItem onClick={removeFromDashboard}>
                  <ListItemIcon>
                    <Remove />
                  </ListItemIcon>
                  <Typography variant="inherit">
                    Remove from dashboard
                  </Typography>
                </MenuItem>
              )}
            </Dropdown>
          </div>
        )}
      </div>
    </Paper>
  )
}
