import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { SwitchToggle } from '@root/components/switch-toggle'
import React from 'react'
import { toggleRestriction } from '../store/actions'
import { useRoleDialogStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  infoText: {
    opacity: 0.6,
    fontWeight: 'normal'
  }
}))

export const Restrictions: React.FC = () => {
  const classes = useStyles()

  const [
    reportSchedulingCapabilities,
    admin,
    onlyShowDashboard
  ] = useRoleDialogStore((state) => [
    state.reportSchedulingCapabilities,
    state.admin,
    state.onlyShowDashboard
  ])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Report scheduling capabilities</Typography>
          <SwitchToggle
            active={reportSchedulingCapabilities}
            toggle={() => toggleRestriction('reportSchedulingCapabilities')}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Admin</Typography>
          <SwitchToggle
            active={admin}
            toggle={() => toggleRestriction('admin')}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5">Only show dashboards</Typography>
            <Typography variant="h5" className={classes.infoText}>
              Removes any data access for the role.
            </Typography>
          </Box>
          <SwitchToggle
            active={onlyShowDashboard}
            toggle={() => toggleRestriction('onlyShowDashboard')}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
