import { SortColumn } from '@shared/models/transactions/sort-column'

const columnMap: Record<SortColumn, undefined> = {
  transaction_id: undefined,
  transaction_chain_id: undefined,
  // FIXME
  status: undefined,
  scheme: undefined,
  // FIXME
  amount_final: undefined,
  amount_initial: undefined,
  platform_id: undefined,
  response_code_id: undefined,
  response_message: undefined,
  reccuring_flag: undefined,
  recurring_id: undefined,
  issuer_id: undefined,
  acquirer_id: undefined,
  terminal_id: undefined,
  terminal_config_id: undefined,
  store_id: undefined,
  merchant_id: undefined,
  merchant_payment_id: undefined,
  merchant_product_id: undefined,
  merchant_product_name: undefined,
  merchant_product_category: undefined,
  merchant_campaign_id: undefined,
  merchant_sales_id: undefined,
  card_lodged_flag: undefined,
  consumer_verification: undefined,
  pan_input_mode: undefined,
  transaction_type_id: undefined,
  payment_method_id: undefined,
  card_type_id: undefined,
  currency: undefined,
  market: undefined,
  card_bin: undefined,
  instrument_identifier: undefined,
  psp_instrument_token: undefined,
  instrument_expiry_date: undefined,
  timestamp_utc: undefined,
  authorization_reason: undefined,
  cvv_result_code: undefined,
  cvv_available_at_auth: undefined,
  result_code_3ds: undefined,
  cvm_code: undefined,
  sca_code: undefined,
  dcc_available: undefined,
  currency_conversion_target_amount: undefined,
  currency_conversion_service_provider: undefined,
  currency_conversion_commission_rate: undefined,
  consumer_postal_code: undefined,
  consumer_market: undefined,
  consumer_email: undefined,
  consumer_first_name: undefined,
  consumer_last_name: undefined,
  transaction_cost: undefined,
  gateway_id: undefined,
  mobile_number: undefined,
  wallet: undefined,
  scheme_transaction_id: undefined,
  scheme_instrument_id: undefined,
  transaction_read_type: undefined
}

export const columns: Array<{
  key: SortColumn
  label: string
}> = Object.keys(columnMap).map((column) => ({
  key: column as SortColumn,
  label: column.replace(/_|-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())
}))
