import { ReportScheduleModel } from '@shared/models/report/schedule'
import create from 'zustand'
import { FieldsModel } from '../models/fields'

export interface ScheduleReportDialogState {
  saving: boolean
  id: number | undefined
  fields: FieldsModel
  dirtyFields: Record<keyof FieldsModel, boolean>
  schedules: Array<ReportScheduleModel>
  recipients: Array<string>
}

export const initialState: ScheduleReportDialogState = {
  saving: false,
  id: undefined,
  fields: {
    name: ''
  },
  dirtyFields: {
    name: false
  },
  schedules: [],
  recipients: []
}

export const [
  useScheduleReportDialogStore,
  scheduleReportDialogStoreApi
] = create<ScheduleReportDialogState>(() => initialState)
