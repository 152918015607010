import { ClientEndpointCollection } from '@shared/transport/http/endpoints'
import { dispatch } from './request'

export const endpoints: ClientEndpointCollection<AbortSignal> = {
  charts: {
    get: {
      dashboard: { dispatch },
      library: { dispatch },
      data: { dispatch }
    },
    manage: {
      rename: { dispatch },
      remove: { dispatch },
      reorder: { dispatch },
      addToDashboard: { dispatch },
      removeFromDashboard: { dispatch },
      link: { dispatch },
      unlink: { dispatch }
    },
    save: {
      library: { dispatch },
      dashboard: { dispatch }
    },
    drafts: {
      save: { dispatch }
    },
    reports: {
      save: { dispatch },
      remove: { dispatch }
    },
    getChartBuilderData: { dispatch }
  },
  dashboards: {
    get: { dispatch },
    getAll: { dispatch },
    save: { dispatch },
    rename: { dispatch },
    remove: { dispatch },
    reorder: { dispatch }
  },
  roles: {
    getAll: { dispatch },
    remove: { dispatch },
    setStatus: { dispatch },
    save: { dispatch }
  },
  transactions: {
    getMany: { dispatch },
    getDetail: { dispatch },
    createTransactionSearch: { dispatch },
    removeTransactionSearch: { dispatch },
    getColumns: { dispatch }
  },
  users: {
    getMe: { dispatch },
    emailLogin: { dispatch },
    passwordLogin: { dispatch },
    sessionLogin: { dispatch },
    getAll: { dispatch },
    remove: { dispatch },
    getEmailAvailability: { dispatch },
    setStatus: { dispatch },
    save: { dispatch }
  },
  implementationGuide: {
    synchronizeTables: { dispatch }
  }
}
