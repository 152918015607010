import { DashboardColor, DashboardType } from '@shared/models/dashboard'
import create from 'zustand'

export interface SaveDialogState {
  view: 'naming' | 'layout'
  id: number
  name: string
  description: string
  type: DashboardType
  color: DashboardColor
  typePage: number
}

export const initialState: SaveDialogState = {
  view: 'naming',
  id: 0,
  name: '',
  description: '',
  type: 'air-freshener',
  color: 'blue',
  typePage: 1
}

export const [useSaveDialogStore, saveDialogStoreApi] = create<SaveDialogState>(
  () => initialState
)
