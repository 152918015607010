import { makeStyles, TableCell, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  alignRight?: boolean
  padding?: 'checkbox' | 'default' | 'none'
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    fontSize: 16,
    color: theme.palette.dark('light'),
    border: 0,
    whiteSpace: 'nowrap',
    '& button': {
      padding: 0
    }
  },
  alignRight: {
    textAlign: 'right'
  }
}))

export const DataCell: React.FC<Props> = ({
  alignRight,
  padding,
  children
}) => {
  const styles = useStyles()

  return (
    <TableCell
      padding={padding}
      className={clsx(styles.root, alignRight && styles.alignRight)}
    >
      {children}
    </TableCell>
  )
}
