import { Box, makeStyles } from '@material-ui/core'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import { StatusType } from '@shared/models/transactions/status-type'
import React from 'react'
import { Status } from '../../../components/status'
import { DetailField } from '../models/detail-field'
import { Flag } from './flag'

interface Props {
  title: string
  fields: Array<DetailField>
}

const useStyles = makeStyles((muiTheme) => ({
  list: {
    padding: muiTheme.spacing(1.5, 1, 3, 1),
    margin: 0,
    listStyle: 'none'
  }
}))

export const Details: React.FC<Props> = ({ title, fields }) => {
  const styles = useStyles()

  return (
    <Box component={Paper}>
      <Box
        px={2}
        py={2.5}
        color={theme.palette.dark()}
        fontSize={13}
        fontWeight={900}
        letterSpacing={0.81}
        borderBottom={`1px solid ${theme.colors.veryLightBlue}`}
      >
        {title}
      </Box>
      <Box component="ul" className={styles.list}>
        {fields.map((field, index) => (
          <Box
            key={index}
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={0.5}
            px={1}
            borderRadius={4}
            bgcolor={index % 2 ? theme.palette.white() : theme.colors.tableGrey}
            fontSize={16}
          >
            <Box color={theme.palette.dark('light')}>{field.label}</Box>
            <Box
              height={22}
              display="flex"
              alignItems="center"
              color={theme.palette.dark()}
            >
              {field.type === 'text' && (field.value || '-')}
              {field.type === 'status' && (
                <Status status={field.value as StatusType} />
              )}
              {field.type === 'flag' && field.value && (
                <Flag country={field.value} small />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
