import { UnauthorizedError } from '@shared/transport/http/errors'
import { usePath, useRedirect, useRoutes } from 'hookrouter'
import React from 'react'
import 'reflect-metadata'
import { brand } from './constants/brand'
import { Layout } from './layout/layout'
import { Dashboard } from './pages/dashboard/dashboard'
import { ImplementationGuide } from './pages/implementation-guide/implementation-guide'
import { Library } from './pages/library/library'
import { Team } from './pages/team/team'
import { Transactions } from './pages/transactions/transactions'
import { authStoreApi } from './store/auth/store'
import { openError } from './store/common/actions'
import { endpoints } from './transport/http/endpoints'
import { setupEndpoints } from './transport/http/setup'

setupEndpoints(endpoints as any)

window.addEventListener('unhandledrejection', (event) => {
  // TODO: Display generic error message
  const { error } = console
  error('uncaught', event.reason)

  if (event.reason instanceof UnauthorizedError) {
    authStoreApi.setState({ authState: 'unauthenticated' })
  } else {
    openError()
  }
})

if (brand === 'generation') {
  document.title = 'generation'
} else {
  document.title = 'fourbee'
}

interface Parameters {
  [key: string]: string
}

interface Routes {
  [key: string]: (parameters: Parameters) => any
}

const Home = () => <div>home</div>

const routes: Routes = {
  '/': () => <Home />,
  '/dashboard': () => <Dashboard />,
  '/dashboard/:id': ({ id }) => <Dashboard id={id} />,
  '/library/*': () => <Library />,
  '/transactions*': () => <Transactions />,
  '/team/*': () => <Team />,
  '/implementation': () => <ImplementationGuide />
}

export const App: React.FC<{}> = () => {
  const routeResult = useRoutes(routes)
  useRedirect('/', '/dashboard')
  useRedirect('/library', '/library/charts')
  usePath() // TODO: Needed for some reason to make the router react to route changes

  return <Layout>{routeResult || <div>Not found</div>}</Layout>
}
