import { Chip, Grid, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { theme } from '@root/constants/theme'
import React from 'react'
import { setRecipients } from '../store/actions'
import { useScheduleReportDialogStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  chip: {
    marginRight: muiTheme.spacing(0.75),
    borderRadius: 3,
    background: theme.colors.veryLightBlue,
    fontSize: 18,
    height: 28
  },
  chipLabel: {
    overflow: 'visible',
    whiteSpace: 'normal'
  }
}))

export const Recipients: React.FC = () => {
  const classes = useStyles()
  const recipients = useScheduleReportDialogStore((state) => state.recipients)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          fullWidth
          freeSolo={true}
          options={[]}
          value={recipients}
          onChange={(event, newValue) =>
            setRecipients(newValue as Array<string>)
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                label={option as any}
                {...getTagProps({ index })}
                classes={{ root: classes.chip, label: classes.chipLabel }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Recipients"
              variant="outlined"
              placeholder="Add email address"
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
