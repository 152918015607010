import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import React, { useEffect, useState } from 'react'
import { addToDashboard, closeDialog, fetchDashboards } from '../store/actions'
import { useLibraryStore } from '../store/store'

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 160
  }
}))

export const AddToDashboardDialog: React.FC<{}> = () => {
  const [activeDialog, dashboards] = useLibraryStore((state) => [
    state.activeDialog,
    state.dashboards
  ])
  const [selectedDashboard, setSelectedDashboard] = useState(0)
  const styles = useStyles()

  useEffect(() => {
    if (activeDialog === 'add-to-dashboard') {
      fetchDashboards()
    }
  }, [activeDialog])

  useEffect(() => {
    if (dashboards.length) {
      setSelectedDashboard(dashboards[0].id)
    }
  }, [dashboards])

  return (
    <Dialog
      open={activeDialog === 'add-to-dashboard'}
      title="Add to dashboard"
      content={
        <Select
          value={selectedDashboard}
          onChange={(e) => setSelectedDashboard(e.target.value as number)}
          className={styles.select}
        >
          {dashboards.map((dashboard, index) => (
            <MenuItem key={index} value={dashboard.id}>
              <Typography variant="subtitle1">{dashboard.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      }
      actions={
        <>
          <OutlineButton secondary onClick={closeDialog}>
            Cancel
          </OutlineButton>
          <Button primary onClick={() => addToDashboard(selectedDashboard)}>
            Save changes
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
