import {
  Step,
  StepLabel,
  Stepper,
  Theme,
  StepConnector
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import StorageIcon from '@material-ui/icons/Storage'
import SyncIcon from '@material-ui/icons/Sync'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import DoneIcon from '@material-ui/icons/Done'
import { brand } from '@root/constants/brand'
import { StepModel } from '../models/step'
import { useImplementationGuideStore } from '../store/store'

const useStyles = makeStyles((muiTheme: Theme) => ({
  stepper: {
    background: 'transparent'
  }
}))

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  }
})

const steps: Array<{ step: StepModel; label: string; icon: JSX.Element }> = [
  { step: 'access', label: 'Access to BigQuery', icon: <StorageIcon /> },
  {
    step: 'synchronize',
    label: 'Synchronize database structure',
    icon: <SyncIcon />
  },
  { step: 'accounts', label: 'Setup accounts', icon: <GroupAddIcon /> },
  {
    step: 'ready',
    label: `${brand === 'generation' ? 'Generation' : 'Fourbee'} ready!`,
    icon: <DoneIcon />
  }
]

const ColorlibStepIcon: React.FC<{
  active: boolean
  completed: boolean
  error: boolean
  icon: number
}> = ({ active, completed, icon }) => {
  const classes = useColorlibStepIconStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {steps[icon - 1].icon}
    </div>
  )
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

export const Steps: React.FC<{}> = ({ children }) => {
  const step = useImplementationGuideStore((state) => state.step)
  const styles = useStyles()

  return (
    <Stepper
      alternativeLabel
      activeStep={steps.findIndex((_step) => _step.step === step)}
      connector={<ColorlibConnector />}
      className={styles.stepper}
    >
      {steps.map((_step, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            {_step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
