import {
  createSetDirtyField,
  createSetField,
  createSetPreviousDirtyFields
} from '@root/helpers/store/form'
import { endpoints } from '@root/transport/http/endpoints'
import { DashboardModel } from '@shared/models/dashboard'
import { saveRole, updateRoleStatus } from '../../../store/actions'
import { roleStoreApi } from '../../../store/store'
import { initialState, roleDialogStoreApi } from './store'

const getSelectedRole = () => {
  const { roles, selectedRoleId } = roleStoreApi.getState()

  return roles.find((_role) => _role.id === selectedRoleId)
}

export const init = async () => {
  const role = getSelectedRole()

  endpoints.dashboards.getAll
    .dispatch(undefined)
    .then(({ dashboards }) =>
      roleDialogStoreApi.setState({ organizationDashboards: dashboards })
    )

  if (role) {
    roleDialogStoreApi.setState({
      fields: {
        name: role.name
      },
      dirtyFields: {
        name: true
      },
      columnFilters: [...role.columnFilters],
      reportSchedulingCapabilities: role.reportSchedulingCapabilities,
      onlyShowDashboard: role.onlyShowDashboard,
      dashboards: [...role.dashboards],
      admin: role.admin,
      status: role.status
    })
  }
}

export const reset = () => roleDialogStoreApi.setState({ ...initialState })
export const setField = createSetField(roleDialogStoreApi)
export const setDirtyField = createSetDirtyField(roleDialogStoreApi)
export const setPreviousDirtyFields = createSetPreviousDirtyFields(
  roleDialogStoreApi,
  ['name']
)

export const addColumnFilter = () =>
  roleDialogStoreApi.setState({
    columnFilters: [
      ...roleDialogStoreApi.getState().columnFilters,
      { column: '', accessFilter: 'contains', value: '', active: true }
    ]
  })

export const removeColumnFilter = (index: number) =>
  roleDialogStoreApi.setState({
    columnFilters: roleDialogStoreApi
      .getState()
      .columnFilters.filter((columnFilter, _index) => _index !== index)
  })

export const updateColumnFilter = (
  index: number,
  key: 'column' | 'accessFilter' | 'value',
  value: string
) =>
  roleDialogStoreApi.setState({
    columnFilters: roleDialogStoreApi
      .getState()
      .columnFilters.map((columnFilter, _index) =>
        _index === index ? { ...columnFilter, [key]: value } : columnFilter
      )
  })

export const toggleColumnFilterActive = (index: number) =>
  roleDialogStoreApi.setState({
    columnFilters: roleDialogStoreApi
      .getState()
      .columnFilters.map((columnFilter, _index) =>
        _index === index
          ? { ...columnFilter, active: !columnFilter.active }
          : columnFilter
      )
  })

export const toggleRestriction = (
  column: 'reportSchedulingCapabilities' | 'onlyShowDashboard' | 'admin'
) =>
  roleDialogStoreApi.setState({
    [column]: !roleDialogStoreApi.getState()[column]
  })

export const addDashboard = (dashboard: DashboardModel) =>
  roleDialogStoreApi.setState({
    dashboards: [...roleDialogStoreApi.getState().dashboards, dashboard]
  })

export const removeDashboard = (dashboard: DashboardModel) =>
  roleDialogStoreApi.setState({
    dashboards: roleDialogStoreApi
      .getState()
      .dashboards.filter((_dashboard) => _dashboard.id !== dashboard.id)
  })

export const setStatus = async () => {
  const { selectedRoleId } = roleStoreApi.getState()
  const status =
    roleDialogStoreApi.getState().status === 'active' ? 'inactive' : 'active'
  roleDialogStoreApi.setState({ saving: true })

  if (selectedRoleId) {
    await endpoints.roles.setStatus.dispatch({ id: selectedRoleId, status })
    roleDialogStoreApi.setState({ status, saving: false })
    updateRoleStatus(status)
  }
}

export const save = async () => {
  const {
    fields: { name },
    columnFilters,
    reportSchedulingCapabilities,
    onlyShowDashboard,
    dashboards,
    admin
  } = roleDialogStoreApi.getState()

  roleDialogStoreApi.setState({ saving: true })

  saveRole(
    name,
    columnFilters,
    reportSchedulingCapabilities,
    onlyShowDashboard,
    dashboards,
    admin
  )
}
