import { PercentageDataList } from '@shared/models/charts/any-chart'
import React, { useRef, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { useLegendHeight } from './helpers/legend-height'
import { renderGradients } from './helpers/render-gradients'
import { renderLegend } from './helpers/render-legend'

interface Props {
  size: 4 | 6 | 12
  data: PercentageDataList
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    name
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const colors2 = ['blue', 'orange', 'turquiose', 'dark', 'green']
const colors = ['#ffe74c', '#ff5964', '#ffffff', '#6bf178', '#35a7ff']

const { log } = console
log(colors, colors2)

export const CircularChart: React.FC<Props> = ({ size, data }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const legendHeight = useLegendHeight(ref)

  if (!data.every((item) => item.count && item.label)) {
    throw new Error('Invalid data')
  }

  return (
    <div
      ref={ref}
      style={{
        opacity: legendHeight ? 1 : 0,
        transition: 'opacity 200ms',
        width: 'calc(100% + 30px)',
        height: (size / 12) * 300 + 100 + legendHeight
      }}
    >
      <ResponsiveContainer>
        <PieChart>
          {renderGradients()}
          {renderLegend()}
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data.map((item) => ({ name: item.label, value: item.count }))}
            innerRadius={(size / 12) * 130}
            outerRadius={(size / 12) * 150}
            paddingAngle={4}
            dataKey="value"
            onMouseEnter={(_, index) => setActiveIndex(index)}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`${colors[index % colors.length]}`}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
