import {
  Box,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem
} from '@material-ui/core'
import { Delete, GetApp, Publish } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import { OutlineButton } from '@root/components/outline-button'
import { PageOverview } from '@root/components/page-overview'
import React, { useState } from 'react'
import { activateDraft, addDraft, removeDraft } from '../store/actions'
import { useChartBuilderStore } from '../store/store'
import { DraftDialog } from './draft-dialog'

interface Props {
  name: string
  goBack: () => void
}

const useStyles = makeStyles(() => ({
  menu: {
    minWidth: 250
  }
}))

export const Overview: React.FC<Props> = ({ name, goBack }) => {
  const styles = useStyles()
  const [draftDialogOpen, setDraftDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const drafts = useChartBuilderStore((state) => state.drafts)

  const activate = (id: number) => {
    activateDraft(id)
    setAnchorEl(null)
  }

  const clickAddDraft = (draftName: string) => {
    addDraft(draftName)
    setDraftDialogOpen(false)
  }

  return (
    <>
      <PageOverview
        title="Chart builder"
        subTitle={name || 'untitled.chart'}
        actionButton={
          <OutlineButton
            secondary
            icon={<Publish />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Drafts
          </OutlineButton>
        }
        goBack={goBack}
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        classes={{ paper: styles.menu }}
        onClose={() => setAnchorEl(null)}
      >
        {!drafts.length && <Box p={1.5}>You have no saved drafts.</Box>}
        {drafts.map((draft, index) => (
          <MenuItem key={index} onClick={() => activate(draft.id)}>
            <ListItemText primary={draft.name} />
            <ListItemSecondaryAction>
              <IconButton
                icon={<Delete />}
                onClick={() => removeDraft(draft.id)}
              />
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
        <Box px={2} pt={2} pb={1}>
          <OutlineButton
            primary
            icon={<GetApp />}
            iconRight
            onClick={() => setDraftDialogOpen(true)}
          >
            Save draft as
          </OutlineButton>
        </Box>
      </Menu>
      <DraftDialog
        open={draftDialogOpen}
        close={() => setDraftDialogOpen(false)}
        add={clickAddDraft}
      />
    </>
  )
}
