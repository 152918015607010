import { SortDirection } from '@root/pages/team/models/sort-direction'
import { MerchantModel } from '@shared/models/user/merchant'
import { UserModel } from '@shared/models/user/user'
import create from 'zustand'
import { ActiveDialog } from '../models/active-dialog'
import { SortColumn } from '../models/sort-column'

interface UserStore {
  viewState: 'fetching' | 'viewing'
  users: Array<UserModel>
  merchants: Array<MerchantModel>
  searchQuery: string
  sortColumn: SortColumn
  sortDirection: SortDirection
  selectedUserId: number | undefined
  activeDialog: ActiveDialog
}

export const [useUserStore, userStoreApi] = create<UserStore>(() => ({
  viewState: 'fetching',
  users: [],
  merchants: [],
  searchQuery: '',
  sortColumn: 'email',
  sortDirection: 'asc',
  selectedUserId: undefined,
  activeDialog: 'none'
}))
