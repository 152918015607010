import { IconButton, makeStyles } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { DataCell } from '@root/components/data-table/data-cell'
import { DataRow } from '@root/components/data-table/data-row'
import { EmptyColumn } from '@root/components/data-table/empty-column'
import { UserModel } from '@shared/models/user/user'
import React from 'react'
import { Status } from '../../../components/status'
import { TeamTable } from '../../../components/team-table'
import { openDialog } from '../../store/actions'
import { useUsers } from '../../store/selectors'
import { useUserStore } from '../../store/store'
import { UserColumn } from '../user-column'
import { UserMenu } from './components/user-menu'

const useStyles = makeStyles((muiTheme) => ({
  listDescription: {
    width: 185,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

export const UserTable: React.FC = () => {
  const classes = useStyles()
  const users = useUsers()
  const viewState = useUserStore((state) => state.viewState)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedUserId, setSelectedUserId] = React.useState<number>(0)

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: UserModel
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedUserId(user.id)
  }

  return (
    <>
      <TeamTable
        loading={viewState === 'fetching'}
        addButtonLabel="Add user"
        columns={
          <>
            <UserColumn label="E-mail" column="email" width="30%" />
            <UserColumn label="Roles" column="roles" width="20%" />
            <UserColumn label="Companies" column="companies" width="20%" />
            <UserColumn label="Status" column="status" width="25%" />
            <EmptyColumn width="5%" />
          </>
        }
        dataRows={
          <>
            {users.map((user, index) => (
              <DataRow key={index}>
                <DataCell>
                  <span
                    onClick={() => openDialog('edit', user.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {user.email}
                  </span>
                </DataCell>
                <DataCell>
                  <div className={classes.listDescription}>
                    {user.roles.map((role) => role.name).join(', ')}
                  </div>
                </DataCell>
                <DataCell>{user.companies}</DataCell>
                <DataCell>
                  <Status status={user.status} />
                </DataCell>
                <DataCell alignRight>
                  <IconButton onClick={(e) => handleOpen(e, user)}>
                    <MoreHoriz />
                  </IconButton>
                </DataCell>
              </DataRow>
            ))}
          </>
        }
        addButtonClick={() => openDialog('edit')}
      />
      <UserMenu
        anchorEl={anchorEl}
        selectedUserId={selectedUserId}
        close={() => setAnchorEl(null)}
      />
    </>
  )
}
