import { makeStyles, Theme, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { AdvancedFilter } from '@root/components/advanced-filter'
import { OutlineButton } from '@root/components/outline-button'
import { Paper } from '@root/components/paper'
import { ChartFromDateType } from '@shared/models/charts/from-date-type'
import React from 'react'
import {
  addFilter,
  removeFilter,
  setFilterColumn,
  setFilterComparator,
  setFilterValue,
  setFromDateType,
  setFromDateValue,
  setToDate,
  setTop
} from '../store/actions'
import { useRealtimeEditorStore } from '../store/store'

const useStyles = makeStyles((muiTheme: Theme) => ({
  filters: {
    padding: muiTheme.spacing(2.5, 3.75),
    maxWidth: 850,
    margin: '0 auto'
  },
  header: {
    marginBottom: muiTheme.spacing(2)
  },
  addButton: {
    marginTop: muiTheme.spacing(2)
  }
}))

export const Filters: React.FC<{}> = () => {
  const styles = useStyles()
  const [
    filters,
    filterColumns,
    fromDateType,
    fromDateValue,
    toDate,
    top
  ] = useRealtimeEditorStore((state) => [
    state.filters,
    state.filterColumns,
    state.fromDateType,
    state.fromDateValue,
    state.toDate,
    state.top
  ])

  return (
    <Paper className={styles.filters}>
      <Typography variant="h6" className={styles.header}>
        FILTERS
      </Typography>
      {filters.map((filter, index) => (
        <AdvancedFilter
          key={index}
          padded
          columns={filterColumns}
          column={filter.column}
          comparator={filter.comparator}
          value={filter.value}
          setColumn={(column) => setFilterColumn(index, column)}
          setComparator={(comparator) => setFilterComparator(index, comparator)}
          setValue={(value) => setFilterValue(index, value)}
          remove={() => removeFilter(index)}
        />
      ))}
      <AdvancedFilter
        padded
        columns={['exact-date', 'last-days', 'last-months', 'last-years']}
        column={fromDateType}
        comparator="equals"
        value={fromDateValue}
        setColumn={(type) => setFromDateType(type as ChartFromDateType)}
        setValue={(value) => setFromDateValue(value)}
      />
      <AdvancedFilter
        padded
        columns={['to-date']}
        column="to-date"
        comparator="equals"
        value={toDate}
        setValue={
          fromDateType === 'exact-date'
            ? (value) => setToDate(value)
            : undefined
        }
      />
      <AdvancedFilter
        padded
        columns={['top']}
        column="top"
        comparator="equals"
        value={top.toString()}
        setValue={(value) => setTop(Number(value))}
      />
      <div className={styles.addButton}>
        <OutlineButton primary icon={<Add />} onClick={addFilter}>
          Add
        </OutlineButton>
      </div>
    </Paper>
  )
}
