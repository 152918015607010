import { Snackbar } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import { theme } from '@root/constants/theme'
import { useAuthStore } from '@root/store/auth/store'
import { closeError } from '@root/store/common/actions'
import { useCommonStore } from '@root/store/common/store'
import React from 'react'
import { Login } from './components/login/login'
import { Mobile } from './components/mobile'
import { Shell } from './components/shell/shell'
import {
  circularStdBlack,
  circularStdBlackItalic,
  circularStdBold,
  circularStdBoldItalic,
  circularStdBook,
  circularStdBookItalic,
  circularStdMedium,
  circularStdMediumItalic
} from './constants/fonts'

const muiTheme = createMuiTheme({
  palette: {
    background: {
      default: theme.colors.background
    },
    primary: {
      main: theme.palette.green(),
      contrastText: '#fff'
    },
    secondary: {
      main: theme.palette.blue()
    },
    error: {
      main: theme.palette.red()
    }
  },
  typography: {
    fontFamily: 'Circular Std',
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    h2: {
      fontSize: 30,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    h3: {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    h4: {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    h5: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    h6: {
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.palette.dark()
    },
    subtitle1: {
      fontSize: 20,
      color: theme.palette.dark()
    },
    subtitle2: {
      fontSize: 17,
      color: theme.palette.dark()
    },
    body1: {
      fontSize: 14,
      color: theme.palette.dark()
    },
    body2: {
      fontSize: 12,
      color: theme.palette.dark()
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          circularStdBlack,
          circularStdBlackItalic,
          circularStdBold,
          circularStdBoldItalic,
          circularStdBook,
          circularStdBookItalic,
          circularStdMedium,
          circularStdMediumItalic
        ]
      }
    }
  }
})

export const Layout: React.FC<{}> = ({ children }) => {
  const authState = useAuthStore((state) => state.authState)
  const error = useCommonStore((state) => state.error)
  // TODO: Switch between Mobile view
  const mobile = false

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {mobile && <Mobile />}
      {!mobile && ['verifying', 'authenticated'].includes(authState) && (
        <Shell>{children}</Shell>
      )}
      {!mobile &&
        [
          'unauthenticated',
          'authenticating',
          'sign-in-email',
          'wrong-details',
          'invalid-link'
        ].includes(authState) && <Login />}
      <Snackbar open={error} autoHideDuration={12000} onClose={closeError}>
        <Alert severity="error" onClose={closeError}>
          <AlertTitle>Error</AlertTitle>
          An unexpected error occured — please contact{' '}
          <strong>the development team</strong>
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
}
