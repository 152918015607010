import { Button as MuiButton, makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  primary?: boolean
  secondary?: boolean
  error?: boolean
  icon?: JSX.Element
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    padding: muiTheme.spacing(1.5, 2),
    color: 'white',
    borderRadius: 3,
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 'bold',
    '& .MuiButton-endIcon > *:first-child': {
      fontSize: 24
    }
  },
  primary: {
    backgroundColor: theme.palette.green(),
    '&:hover': {
      backgroundColor: theme.colors.darkGreen
    }
  },
  secondary: {
    backgroundColor: theme.palette.blue(),
    '&:hover': {
      backgroundColor: theme.colors.darkBlue
    }
  },
  error: {
    backgroundColor: theme.palette.red(),
    '&:hover': {
      backgroundColor: theme.colors.darkRed
    }
  },
  disabled: {
    backgroundColor: theme.palette.dark('lightest')
  },
  icon: {
    padding: muiTheme.spacing(1.125, 2),
    '& .MuiButton-endIcon': {
      marginLeft: muiTheme.spacing(4)
    }
  }
}))

export const Button: React.FC<Props> = ({
  primary,
  secondary,
  error,
  icon,
  disabled,
  onClick,
  children
}) => {
  const styles = useStyles()
  return (
    <MuiButton
      variant="text"
      disableRipple
      endIcon={icon}
      disabled={disabled}
      className={clsx(
        styles.root,
        primary && styles.primary,
        secondary && styles.secondary,
        error && styles.error,
        disabled && styles.disabled,
        icon && styles.icon
      )}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  )
}
