import {
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme
} from '@material-ui/core'
import { Delete, ExpandMore } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import { OutlineButton } from '@root/components/outline-button'
import React from 'react'
import {
  activateSavedSearch,
  createNewSavedSearch,
  removeSavedSearch
} from '../../../store/actions'
import { useFilters } from '../../../store/selectors'
import { useTransactionStore } from '../../../store/store'
import { SavedSearchNameField } from './saved-search-name-field'

const useStyles = makeStyles((muiTheme: Theme) => ({
  savedSearches: {
    padding: muiTheme.spacing(1, 2.5),
    marginLeft: 'auto'
  },
  menu: {
    minWidth: 250
  }
}))

export const SavedSearches = () => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const filters = useFilters()
  const savedSearches = useTransactionStore((state) => state.savedSearches)

  const activate = (id: number) => {
    activateSavedSearch(id)
    setAnchorEl(null)
  }

  return (
    <div className={styles.savedSearches}>
      <OutlineButton
        primary
        iconRight
        icon={<ExpandMore />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Saved searches
      </OutlineButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        classes={{ paper: styles.menu }}
        onClose={() => setAnchorEl(null)}
      >
        {savedSearches.map((savedSearch, index) => (
          <MenuItem key={index} onClick={() => activate(savedSearch.id)}>
            <ListItemText primary={savedSearch.name} />
            <ListItemSecondaryAction>
              <IconButton
                icon={<Delete />}
                onClick={() => removeSavedSearch(savedSearch.id)}
              />
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
        <SavedSearchNameField />
        <OutlineButton
          primary
          disabled={!filters.length}
          onClick={createNewSavedSearch}
        >
          Save new filter
        </OutlineButton>
      </Menu>
    </div>
  )
}
