import { theme } from '@root/constants/theme'
import { TimelineData } from '@shared/models/charts/any-chart'
import moment from 'moment'
import React from 'react'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { renderGradients } from './helpers/render-gradients'
import { renderLegend } from './helpers/render-legend'
import { renderQuarterTick } from './helpers/render-quarter-tick'
import { renderTooltip } from './helpers/render-tooltip'
import { tickFormatter } from './helpers/tick-formatter'

interface Props {
  data: TimelineData
  size: 4 | 6 | 12
}

const colors = [
  theme.palette.blue(),
  theme.palette.orange(),
  theme.palette.turquiose()
]

export const LineChart: React.FC<Props> = ({ data, size }) => {
  if (!data.labels.length || !data.dataList.every((item) => item.date)) {
    throw new Error('Invalid data')
  }

  return (
    // TODO: Consider a better way to eliminate the 30px margin from recharts
    <div style={{ width: 'calc(100% + 30px)', height: '375px' }}>
      <ResponsiveContainer>
        <ComposedChart
          data={data.dataList}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          {renderGradients()}
          {renderLegend()}
          <CartesianGrid strokeDasharray="12 8" vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(timeStr) => `${moment(timeStr).format('MMM Do')}`}
            tick={{
              fontSize: 18,
              fill: theme.palette.dark('lighter')
            }}
            height={70}
            dy={14}
            interval={Math.round((data.dataList.length / 8 / size) * 12)}
            axisLine={false}
            tickLine={false}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={renderQuarterTick}
            height={1}
            scale="band"
            xAxisId="quarter"
          />
          <YAxis
            tickFormatter={tickFormatter}
            tickCount={6}
            tick={{
              fontSize: 18,
              fill: theme.palette.dark('lighter')
            }}
            dx={-10}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tickFormatter={tickFormatter}
            yAxisId="right"
            orientation="right"
            tickCount={6}
            tick={{
              fontSize: 18,
              fill: theme.palette.dark('lighter')
            }}
            dx={10}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={renderTooltip} />
          {data.labels.map((label, index) => (
            <Line
              key={index}
              name={label.name}
              type="monotone"
              dataKey={label.key}
              stroke={colors[index]}
              strokeWidth={3}
              dot={false}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
