import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { Delete, Edit as EditIcon } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import { theme } from '@root/constants/theme'
import { DashboardDataChartModel } from '@shared/models/charts/any-chart'
import React from 'react'
import { openDialog } from '../../../../../store/actions'

interface Props {
  chart: DashboardDataChartModel
  dragProps: Record<string, any>
}

const useStyles = makeStyles(() => ({
  image: {
    width: 40,
    height: 40
  }
}))

export const ReorderChart: React.FC<Props> = ({ chart, dragProps }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      position="relative"
      mb={1}
      py={2}
      pl={4}
      bgcolor={theme.palette.white()}
      boxShadow="0 1px 2px 0 rgba(0, 27, 66, 0.24)"
      borderRadius={14}
      {...dragProps}
    >
      <div>
        <Box mb={2}>
          <Typography variant="h4">{chart.name}</Typography>
        </Box>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <img
              alt="query"
              src={`images/chart-queries/${chart.queryImage}`}
              className={classes.image}
            />
          </Grid>
          <Grid item>
            <img
              alt="type"
              src={`images/chart-types/${chart.typeImage}`}
              className={classes.image}
            />
          </Grid>
        </Grid>
      </div>
      <Box px={2} display="flex" alignItems="center">
        <Grid container wrap="nowrap">
          <Grid item>
            <IconButton
              large
              icon={<EditIcon />}
              onClick={() => openDialog('rename-chart', chart.id)}
            />
          </Grid>
          <Grid item>
            <IconButton
              large
              icon={<Delete />}
              onClick={() => openDialog('delete-chart', chart.id)}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
