import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import MonacoEditor from 'react-monaco-editor'
import { setCustomQuery } from '../../../store/actions'
import { useChartBuilderStore } from '../../../store/store'

const useStyles = makeStyles((muiTheme: Theme) => ({
  title: {
    letterSpacing: '0.81px',
    textTransform: 'uppercase',
    fontWeight: 900,
    marginBottom: muiTheme.spacing(2)
  }
}))

export const Custom: React.FC<{}> = () => {
  const styles = useStyles()
  const customQuery = useChartBuilderStore((store) => store.customQuery)

  return (
    <Box pb={2}>
      <Typography variant="body1" className={styles.title}>
        Enter custom query
      </Typography>
      <MonacoEditor
        height="400"
        language="mysql"
        theme="vs-dark"
        value={customQuery}
        options={{ selectOnLineNumbers: true, minimap: { enabled: false } }}
        onChange={(value) => setCustomQuery(value)}
        editorDidMount={(editor) => editor.focus()}
      />
    </Box>
  )
}
