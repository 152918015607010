import {
  IconButton as MuiIconButton,
  makeStyles,
  Theme
} from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  icon: JSX.Element
  padding?: boolean
  primary?: boolean
  secondary?: boolean
  small?: boolean
  mediumSmall?: boolean
  medium?: boolean
  large?: boolean
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    padding: 0
  },
  padding: {
    padding: muiTheme.spacing(0.5)
  },
  primary: {
    backgroundColor: theme.palette.blue('lightest'),
    color: theme.palette.blue(),
    '&:hover': {
      backgroundColor: theme.palette.blue('lighter')
    }
  },
  secondary: {
    backgroundColor: theme.colors.veryLightBlue,
    '&:hover': {
      backgroundColor: '#dae1ec',
      color: theme.palette.blue()
    }
  },
  small: {
    width: 28,
    height: 28,
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  mediumSmall: {
    width: 33,
    height: 33,
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  medium: {
    width: 40,
    height: 40,
    '& .MuiSvgIcon-root': {
      fontSize: 24
    }
  },
  large: {
    width: 60,
    height: 60,
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  }
}))

export const IconButton: React.FC<Props> = ({
  icon,
  padding,
  primary,
  secondary,
  small,
  mediumSmall,
  medium,
  large,
  disabled,
  onClick
}) => {
  const styles = useStyles()
  return (
    <MuiIconButton
      disableRipple
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        styles.root,
        padding && styles.padding,
        primary && styles.primary,
        secondary && styles.secondary,
        small && styles.small,
        mediumSmall && styles.mediumSmall,
        medium && styles.medium,
        large && styles.large
      )}
    >
      {icon}
    </MuiIconButton>
  )
}
