import CircularStdBlackEot from '@root/assets/fonts/CircularStd-Black.eot'
import CircularStdBlackTtf from '@root/assets/fonts/CircularStd-Black.ttf'
import CircularStdBlackWoff from '@root/assets/fonts/CircularStd-Black.woff'
import CircularStdBlackWoff2 from '@root/assets/fonts/CircularStd-Black.woff2'
import CircularStdBlackItalicEot from '@root/assets/fonts/CircularStd-BlackItalic.eot'
import CircularStdBlackItalicTtf from '@root/assets/fonts/CircularStd-BlackItalic.ttf'
import CircularStdBlackItalicWoff from '@root/assets/fonts/CircularStd-BlackItalic.woff'
import CircularStdBlackItalicWoff2 from '@root/assets/fonts/CircularStd-BlackItalic.woff2'
import CircularStdBoldEot from '@root/assets/fonts/CircularStd-Bold.eot'
import CircularStdBoldTtf from '@root/assets/fonts/CircularStd-Bold.ttf'
import CircularStdBoldWoff from '@root/assets/fonts/CircularStd-Bold.woff'
import CircularStdBoldWoff2 from '@root/assets/fonts/CircularStd-Bold.woff2'
import CircularStdBoldItalicEot from '@root/assets/fonts/CircularStd-BoldItalic.eot'
import CircularStdBoldItalicTtf from '@root/assets/fonts/CircularStd-BoldItalic.ttf'
import CircularStdBoldItalicWoff from '@root/assets/fonts/CircularStd-BoldItalic.woff'
import CircularStdBoldItalicWoff2 from '@root/assets/fonts/CircularStd-BoldItalic.woff2'
import CircularStdBookEot from '@root/assets/fonts/CircularStd-Book.eot'
import CircularStdBookTtf from '@root/assets/fonts/CircularStd-Book.ttf'
import CircularStdBookWoff from '@root/assets/fonts/CircularStd-Book.woff'
import CircularStdBookWoff2 from '@root/assets/fonts/CircularStd-Book.woff2'
import CircularStdBookItalicEot from '@root/assets/fonts/CircularStd-BookItalic.eot'
import CircularStdBookItalicTtf from '@root/assets/fonts/CircularStd-BookItalic.ttf'
import CircularStdBookItalicWoff from '@root/assets/fonts/CircularStd-BookItalic.woff'
import CircularStdBookItalicWoff2 from '@root/assets/fonts/CircularStd-BookItalic.woff2'
import CircularStdMediumEot from '@root/assets/fonts/CircularStd-Medium.eot'
import CircularStdMediumTtf from '@root/assets/fonts/CircularStd-Medium.ttf'
import CircularStdMediumWoff from '@root/assets/fonts/CircularStd-Medium.woff'
import CircularStdMediumWoff2 from '@root/assets/fonts/CircularStd-Medium.woff2'
import CircularStdMediumItalicEot from '@root/assets/fonts/CircularStd-MediumItalic.eot'
import CircularStdMediumItalicTtf from '@root/assets/fonts/CircularStd-MediumItalic.ttf'
import CircularStdMediumItalicWoff from '@root/assets/fonts/CircularStd-MediumItalic.woff'
import CircularStdMediumItalicWoff2 from '@root/assets/fonts/CircularStd-MediumItalic.woff2'

export const circularStdBlack = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Black'), local('CircularStd-Black'),
		url(${CircularStdBlackEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdBlackWoff2}) format('woff2'),
		url(${CircularStdBlackWoff}) format('woff'),
    url(${CircularStdBlackTtf}) format('truetype')
  `,
  fontWeight: 900,
  fontStyle: 'normal'
}

export const circularStdBlackItalic = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Black Italic'), local('CircularStd-BlackItalic'),
		url(${CircularStdBlackItalicEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdBlackItalicWoff2}) format('woff2'),
		url(${CircularStdBlackItalicWoff}) format('woff'),
    url(${CircularStdBlackItalicTtf}) format('truetype')
  `,
  fontWeight: 900,
  fontStyle: 'italic'
}

export const circularStdBold = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Bold'), local('CircularStd-Bold'),
		url(${CircularStdBoldEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdBoldWoff2}) format('woff2'),
		url(${CircularStdBoldWoff}) format('woff'),
    url(${CircularStdBoldTtf}) format('truetype')
  `,
  fontWeight: 'bold',
  fontStyle: 'normal'
}

export const circularStdBoldItalic = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'),
		url(${CircularStdBoldItalicEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdBoldItalicWoff2}) format('woff2'),
		url(${CircularStdBoldItalicWoff}) format('woff'),
    url(${CircularStdBoldItalicTtf}) format('truetype')
  `,
  fontWeight: 'bold',
  fontStyle: 'italic'
}

export const circularStdBook = {
  fontFamily: 'Circular Std',
  src: `
    local('Circular Std Book'), local('CircularStd-Book'),
    url('${CircularStdBookEot}?#iefix') format('embedded-opentype'),
    url(${CircularStdBookWoff2}) format('woff2'),
    url(${CircularStdBookWoff}) format('woff'),
    url(${CircularStdBookTtf}) format('truetype')
  `,
  fontWeight: 'normal',
  fontStyle: 'normal'
}

export const circularStdBookItalic = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Book Italic'), local('CircularStd-BookItalic'),
		url(${CircularStdBookItalicEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdBookItalicWoff2}) format('woff2'),
		url(${CircularStdBookItalicWoff}) format('woff'),
    url(${CircularStdBookItalicTtf}) format('truetype')
  `,
  fontWeight: 'normal',
  fontStyle: 'italic'
}

export const circularStdMedium = {
  fontFamily: 'Circular Std',
  src: `
		local('Circular Std Medium'), local('CircularStd-Medium'),
		url(${CircularStdMediumEot}?#iefix) format('embedded-opentype'),
		url(${CircularStdMediumWoff2}) format('woff2'),
		url(${CircularStdMediumWoff}) format('woff'),
    url(${CircularStdMediumTtf}) format('truetype')
  `,
  fontWeight: 500,
  fontStyle: 'normal'
}

export const circularStdMediumItalic = {
  fontFamily: 'Circular Std',
  src: `
    	local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'),
      url(${CircularStdMediumItalicEot}?#iefix) format('embedded-opentype'),
      url(${CircularStdMediumItalicWoff2}) format('woff2'),
      url(${CircularStdMediumItalicWoff}) format('woff'),
      url(${CircularStdMediumItalicTtf}) format('truetype')
    `,
  fontWeight: 500,
  fontStyle: 'italic'
}
