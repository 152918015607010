import { DashboardModel } from '@shared/models/dashboard'
import { RoleColumnFilter } from '@shared/models/role/column-filter'
import { RoleStatus } from '@shared/models/role/status'
import create from 'zustand'
import { FieldsModel } from '../models/fields'

export interface RoleDialogState {
  saving: boolean
  fields: FieldsModel
  dirtyFields: Record<keyof FieldsModel, boolean>
  columnFilters: Array<RoleColumnFilter>
  reportSchedulingCapabilities: boolean
  onlyShowDashboard: boolean
  organizationDashboards: Array<DashboardModel>
  dashboards: Array<DashboardModel>
  admin: boolean
  status: RoleStatus
}

export const initialState: RoleDialogState = {
  saving: false,
  fields: {
    name: ''
  },
  dirtyFields: {
    name: false
  },
  columnFilters: [],
  reportSchedulingCapabilities: false,
  onlyShowDashboard: false,
  organizationDashboards: [],
  dashboards: [],
  admin: false,
  status: 'active'
}

export const [useRoleDialogStore, roleDialogStoreApi] = create<RoleDialogState>(
  () => initialState
)
