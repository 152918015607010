import { makeStyles, Menu, MenuProps, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

const useStyles = makeStyles((muiTheme: Theme) => ({
  menu: {
    minWidth: 200,
    fontSize: 16,
    color: theme.palette.dark(),
    '& span, & svg': {
      opacity: 0.7
    }
  }
}))

export const DropdownMenu: React.FC<MenuProps> = (props) => {
  const styles = useStyles()

  return <Menu {...props} classes={{ paper: styles.menu }} />
}
