import { RoleModel } from '@shared/models/role/role'
import { UserStatus } from '@shared/models/user/status'
import create from 'zustand'
import { FieldsModel } from '../models/fields'

export interface UserDialogState {
  saving: boolean
  emailState: 'validating' | 'taken' | 'available'
  fields: FieldsModel
  dirtyFields: Record<keyof FieldsModel, boolean>
  organizationRoles: Array<RoleModel>
  roles: Array<RoleModel>
  merchants: Array<string>
  status: UserStatus
  selectedRoleId: number | undefined
}

export const initialState: UserDialogState = {
  saving: false,
  emailState: 'validating',
  fields: {
    email: '',
    singleSignOn: ''
  },
  dirtyFields: {
    email: false,
    singleSignOn: true
  },
  organizationRoles: [],
  roles: [],
  merchants: [],
  status: 'active',
  selectedRoleId: 1
}

export const [useUserDialogStore, userDialogStoreApi] = create<UserDialogState>(
  () => initialState
)
