import { DashboardDataChartModel } from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import { ReportModel } from '@shared/models/report/report'
import create from 'zustand'
import { ActiveDialog } from './models/active-dialog'

interface DashboardStore {
  viewState:
  | 'fetching-all'
  | 'fetching-dashboard'
  | 'viewing'
  | 'edit-order'
  | 'edit-reports'
  | 'chart-builder'
  | 'realtime-edit'
  dashboards: Array<DashboardModel>
  charts: Array<DashboardDataChartModel>
  reports: Array<ReportModel>
  selectedDashboardId: number | undefined
  selectedDialogEntityId: number | undefined
  activeDialog: ActiveDialog
}

export const initialState: DashboardStore = {
  viewState: 'fetching-all',
  dashboards: [],
  charts: [],
  reports: [],
  selectedDashboardId: undefined,
  selectedDialogEntityId: undefined,
  activeDialog: 'none'
}

export const [useDashboardStore, dashboardStoreApi] = create<DashboardStore>(
  () => initialState
)
