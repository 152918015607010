import { ChartModel } from '@shared/models/charts/chart'
import { ChartMethod } from '@shared/models/charts/chart-method'
import { ChartTypeModel } from '@shared/models/charts/chart-type'
import { ChartTypeVariant } from '@shared/models/charts/chart-type-variant'
import { FilterModel } from '@shared/models/charts/filter'
import { ChartFromDateType } from '@shared/models/charts/from-date-type'
import { ChartQueryModel } from '@shared/models/charts/query'
import { AdvancedChartQueryModel } from '@shared/models/charts/advanced-query'
import create from 'zustand'
import { Step } from '../models/step'

export interface ChartBuilderState {
  viewState: 'viewing' | 'fetching' | 'saving'
  types: Array<ChartTypeModel>
  queries: Array<ChartQueryModel>
  advancedQueries: Array<AdvancedChartQueryModel>
  drafts: Array<ChartModel>
  filterColumns: Array<string>
  id: number | undefined
  name: string
  method: ChartMethod
  variant: ChartTypeVariant
  currentStep: Step
  queryId: number
  advancedQueryId: number
  typeId: number
  customQuery: string
  fromDateType: ChartFromDateType
  fromDateValue: string
  toDate: string
  top: number
  filters: Array<FilterModel>
  data: any
  chartRenderError: boolean
}

export const initialState: ChartBuilderState = {
  viewState: 'fetching',
  types: [],
  queries: [],
  advancedQueries: [],
  drafts: [],
  filterColumns: [],
  id: undefined,
  name: '',
  method: 'regular',
  variant: 'circular',
  currentStep: 'what-to-see',
  queryId: 0,
  advancedQueryId: 0,
  typeId: 0,
  customQuery: `SELECT pm.name as label, COUNT(t.transaction_id) as \`count\`
    FROM \`fourbee\`.\`fourbee_test\`.\`transactions_new\` t
    INNER JOIN \`fourbee\`.\`fourbee_test\`.\`payment_methods\` pm on t.payment_method_id = pm.id
    WHERE t.amount_final > 950
    GROUP BY pm.name`,
  fromDateType: 'last-days',
  fromDateValue: '30',
  toDate: '',
  top: 100,
  filters: [],
  data: undefined,
  chartRenderError: false
}

export const [useChartBuilderStore, chartBuilderStoreApi] = create<
  ChartBuilderState
>(() => initialState)
