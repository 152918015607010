import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Button } from '@root/components/button'
import React from 'react'
import { createAdvanced, createCustom } from '../store/actions'

interface Props {
  canTraverse: boolean
  canToggleMethod: boolean
  traverseStep: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  builder: {
    padding: muiTheme.spacing(6, 12.25, 7.5, 12.25)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export const Builder: React.FC<Props> = ({
  canTraverse,
  canToggleMethod,
  children,
  traverseStep
}) => {
  const styles = useStyles()

  return (
    <div className={styles.builder}>
      {children}
      {canTraverse && (
        <Box mt={4}>
          <Grid container justify="flex-end" spacing={2}>
            {canToggleMethod && (
              <>
                <Grid item>
                  <Button
                    secondary
                    icon={<ArrowForward />}
                    onClick={createCustom}
                  >
                    Custom
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    secondary
                    icon={<ArrowForward />}
                    onClick={createAdvanced}
                  >
                    Advanced
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button primary icon={<ArrowForward />} onClick={traverseStep}>
                Next step
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  )
}
