import { App } from '@root/app'
import React from 'react'
import ReactDOM from 'react-dom'
import { productSite } from './product-site'
import { productSiteGeneration } from './product-site-generation'
import * as serviceWorker from './service-worker'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
declare const module: any

if (
  window.location.hostname !== 'localhost' &&
  window.location.protocol !== 'https:'
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  )
}

if (
  true ||
  window.location.host === 'fourbee.io' ||
  window.location.host === 'www.fourbee.io'
) {
  document.write(productSite)
} else if (
  window.location.host === 'thegenerationanalytics.com' ||
  window.location.host === 'www.thegenerationanalytics.com'
) {
  document.write(productSiteGeneration)
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()

  if (module.hot) {
    module.hot.accept('./app', () => {
      /* eslint-disable-next-line global-require */
      const NextApp = require('./app').App
      ReactDOM.render(<NextApp />, document.getElementById('root'))
    })
  }
}
