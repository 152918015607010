import {
  Chip,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { AdvancedFilter } from '@root/components/advanced-filter'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { OutlineButton } from '@root/components/outline-button'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import { SortColumn } from '@shared/models/transactions/sort-column'
import React from 'react'
import {
  addFilter,
  removeFilter,
  saveFilterColumn,
  selectFilterColumn,
  setFilterColumn,
  setFilterComparator,
  setFilterValue
} from '../../store/actions'
import { useFilters, useUnusedFilterColumns } from '../../store/selectors'
import { SavedSearches } from './component/saved-searches'

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  addButton: {
    color: theme.palette.dark('light'),
    padding: muiTheme.spacing(2, 2.5)
  },
  filters: {
    minHeight: 52,
    padding: muiTheme.spacing(1, 2.5),
    borderLeft: `1px solid ${theme.palette.dark('lightest')}`
  },
  filter: {
    display: 'flex',
    alignItems: 'center'
  },
  filterColumn: {
    color: theme.palette.dark('light'),
    fontWeight: 'normal'
  },
  filterValue: {
    fontSize: 18,
    margin: muiTheme.spacing(0.25, 0.5, 0, 1),
    '&:before': {
      borderBottom: 0
    }
  },
  removeFilter: {
    display: 'flex',
    padding: muiTheme.spacing(0.25),
    background: theme.colors.veryLightBlue,
    borderRadius: '100%',
    cursor: 'pointer'
  },
  removeFilterIcon: {
    fontSize: 14
  }
}))

export const Filter = () => {
  const styles = useStyles()
  const columns = useUnusedFilterColumns()
  const filters = useFilters()

  return (
    <Paper className={styles.root}>
      <div className={styles.addButton}>
        <Dropdown
          staticHeight
          button={
            <OutlineButton primary large icon={<Add />}>
              Add filter
            </OutlineButton>
          }
        >
          {columns.map((column, index) => (
            <MenuItem key={index} onClick={() => addFilter(column.column)}>
              <Typography variant="inherit">{column.label}</Typography>
            </MenuItem>
          ))}
        </Dropdown>
      </div>
      <div className={styles.filters}>
        <Grid container spacing={3}>
          {filters.map((filter, index) => (
            <Grid key={index} item>
              {!filter.selected && (
                <Chip
                  label={`${filter.column} ${filter.comparator} ${filter.value}`}
                  onClick={() => selectFilterColumn(filter.column)}
                  onDelete={() => removeFilter(filter.column)}
                />
              )}
              {filter.selected && (
                <AdvancedFilter
                  columns={columns.map((column) => column.column)}
                  column={filter.column}
                  comparator={filter.comparator}
                  value={filter.value}
                  setColumn={(column) =>
                    setFilterColumn(filter.column, column as SortColumn)
                  }
                  setComparator={(comparator) =>
                    setFilterComparator(filter.column, comparator)
                  }
                  setValue={(value) => setFilterValue(filter.column, value)}
                  save={saveFilterColumn}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
      <SavedSearches />
    </Paper>
  )
}
