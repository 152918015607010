import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { brand } from '@root/constants/brand'
import { theme } from '@root/constants/theme'
import { authenticate } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import React, { useEffect } from 'react'
import { EmailField } from './components/email-field'
import { PasswordField } from './components/password-field'
import { MethodButton } from './components/method-button'
import { SignInButton } from './components/sign-in-button'
import { EmailLoginMessage } from './components/email-login-message'

const useStyles = makeStyles((muiTheme) => ({
  paper: {
    marginTop: muiTheme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: 60,
    fontWeight: 900,
    letterSpacing: -2,
    marginBottom: muiTheme.spacing(4)
  },
  icon: {
    width: 48,
    height: 48,
    color: theme.palette.dark('lightest')
  },
  errorMessage: {
    marginTop: muiTheme.spacing(1),
    color: red[600],
    fontWeight: 'bold'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: muiTheme.spacing(4)
  }
}))

export const Login: React.FC<{}> = () => {
  const [authState, signInMethod] = useAuthStore((state) => [
    state.authState,
    state.signInMethod
  ])
  const classes = useStyles()

  useEffect(() => {
    const mvp = document.getElementsByName('viewport')
    mvp[0]?.setAttribute('content', 'width=device-width')
    mvp[0]?.setAttribute('content', 'initial-scale=1.0')

    return () => {
      mvp[0]?.removeAttribute('content')
      mvp[0]?.setAttribute('content', 'maximum-scale=1.0')
    }
  }, [])

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    authenticate()
    e.preventDefault()
  }

  console.log(authState, signInMethod)

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography variant="h1" className={classes.title}>
          {brand === 'generation' ? 'generation' : 'fourbee'}
        </Typography>
        <LockOutlinedIcon className={classes.icon} />
        {authState === 'wrong-details' && (
          <Typography component="p" className={classes.errorMessage}>
            Wrong username / password, please try again.
          </Typography>
        )}
        {authState === 'invalid-link' && (
          <Typography component="p" className={classes.errorMessage}>
            The link is invalid or has expired, please login again.
          </Typography>
        )}
        <form className={classes.form} noValidate onSubmit={submit}>
          {[
            'unauthenticated',
            'authenticating',
            'wrong-details',
            'invalid-link'
          ].includes(authState) ? (
            <>
              <EmailField />
              {signInMethod === 'password' && <PasswordField />}
              <SignInButton />
              <MethodButton />
            </>
          ) : (
            <EmailLoginMessage />
          )}
        </form>
      </div>
    </Container>
  )
}
