import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core'
import { Delete, Edit, MoreHoriz } from '@material-ui/icons'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { IconButton } from '@root/components/icon-button'
import { Paper } from '@root/components/paper'
import { dashboardColors } from '@root/constants/dashboard-colors'
import { dashboardIcons } from '@root/constants/dashboard-icons'
import { theme } from '@root/constants/theme'
import { DashboardDetailModel } from '@shared/models/dashboard'
import React from 'react'
import { openDialog } from '../store/actions'

interface Props {
  dashboard: DashboardDetailModel
  dragProps: Record<string, any>
}

const useStyles = makeStyles((muiTheme) => ({
  chart: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: muiTheme.spacing(3)
  },
  dashboardTypeIcon: {
    width: 24,
    height: 24,
    fill: '#fff'
  },
  dashboardTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    marginBottom: muiTheme.spacing(0.5)
  },
  dashboardDescription: {
    color: theme.palette.dark('light'),
    lineHeight: 1
  }
}))

export const Dashboard: React.FC<Props> = ({ dragProps, dashboard }) => {
  const classes = useStyles()

  const TypeIcon = dashboardIcons.find(
    (typeIcon) => typeIcon.type === dashboard.type
  )?.icon

  if (!TypeIcon) {
    throw new Error()
  }

  return (
    <ListItem disableGutters {...dragProps}>
      <Paper rounded className={classes.chart}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Avatar
              style={{
                width: 40,
                height: 40,
                background: dashboardColors.find(
                  (dashboardColor) => dashboardColor.color === dashboard.color
                )?.code
              }}
            >
              <TypeIcon className={classes.dashboardTypeIcon} />
            </Avatar>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" className={classes.dashboardTitle}>
              {dashboard.label}
            </Typography>
            <Typography
              variant="body1"
              className={classes.dashboardDescription}
            >
              {dashboard.description}
            </Typography>
          </Box>
        </Box>
        <Box position="absolute" mt={1} mr={1.75} top={0} right={0}>
          <Dropdown button={<IconButton icon={<MoreHoriz />} padding />}>
            <MenuItem onClick={() => openDialog('add', dashboard.id)}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>

            <MenuItem onClick={() => openDialog('delete', dashboard.id)}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <Typography variant="inherit">Delete</Typography>
            </MenuItem>
          </Dropdown>
        </Box>
        <Box
          position="absolute"
          right={24}
          bottom={8}
          fontSize={14}
          fontStyle="italic"
          color={theme.palette.dark('lighter')}
        >
          {dashboard.created}
        </Box>
      </Paper>
    </ListItem>
  )
}
