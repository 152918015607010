import { makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  status: 'active' | 'inactive'
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: muiTheme.spacing(1.5),
    backgroundColor: theme.palette.dark('lighter'),
    borderRadius: '100%'
  },
  circleActive: {
    background: theme.palette.green()
  },
  status: {
    textTransform: 'capitalize'
  }
}))

export const Status: React.FC<Props> = ({ status }) => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <div
        className={clsx(
          styles.circle,
          status === 'active' && styles.circleActive
        )}
      />
      <span className={styles.status}>{status}</span>
    </div>
  )
}
