import { Box, makeStyles, TextField } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  label: string
  value: any
  required: boolean
  disabled: boolean
  error?: string
  select?: boolean
  password?: boolean
  autoFocus?: boolean
  minWidth?: number
  setField: (value: any) => void
  setDirtyField?: () => void
  setPreviousDirtyFields?: () => void
  remove?: () => void
}

const useStyles = makeStyles((muiTheme) => ({
  textField: {
    '& .MuiInputBase-root': {
      marginTop: muiTheme.spacing(2.5),
      padding: muiTheme.spacing(1.5),
      background: theme.colors.veryLightBlue,
      borderRadius: 4,
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInputBase-input': {
      padding: 0
    },
    '& .MuiFormLabel-root': {
      fontSize: 17.33,
      fontWeight: 500
    }
  },
  removeButton: {
    position: 'absolute',
    top: 12,
    right: -8,
    cursor: 'pointer'
  }
}))

export const FormField: React.FC<Props> = ({
  label,
  value,
  required,
  disabled,
  error,
  select,
  password,
  autoFocus,
  minWidth,
  children,
  setField,
  setDirtyField,
  setPreviousDirtyFields,
  remove
}) => {
  const classes = useStyles()

  return (
    <Box position="relative" minWidth={minWidth || 'auto'}>
      <TextField
        variant="standard"
        margin="none"
        required={required}
        fullWidth
        label={label}
        select={select}
        type={password ? 'password' : 'text'}
        autoFocus={autoFocus}
        value={value}
        error={!!error}
        disabled={disabled}
        helperText={error}
        onChange={(e) => setField(e.target.value)}
        onBlur={setDirtyField}
        onFocus={setPreviousDirtyFields}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      >
        {children}
      </TextField>
      {remove && (
        <Box className={classes.removeButton} onClick={remove}>
          <Cancel fontSize="small" color="error" />
        </Box>
      )}
    </Box>
  )
}
