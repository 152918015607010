import { Box, Grid, makeStyles, MenuItem, TextField } from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { FormField } from '@root/components/form-field'
import { OutlineButton } from '@root/components/outline-button'
import { SwitchToggle } from '@root/components/switch-toggle'
import { theme } from '@root/constants/theme'
import React from 'react'
import { useRoleStore } from '../../../store/store'
import { addColumnFilter, removeColumnFilter, toggleColumnFilterActive, updateColumnFilter } from '../store/actions'
import { useRoleDialogStore } from '../store/store'

const useStyles = makeStyles((muiTheme) => ({
  textField: {
    '& .MuiInputBase-root': {
      marginTop: muiTheme.spacing(2.5),
      padding: muiTheme.spacing(0.75, 1.5),
      background: theme.colors.veryLightBlue,
      borderRadius: 4,
      fontSize: 18,
      fontWeight: 500
    },
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInputBase-input': {
      padding: 0
    },
    '& .MuiFormLabel-root': {
      fontSize: 17.33,
      fontWeight: 500
    }
  },
  removeButton: {
    position: 'absolute',
    top: 12,
    right: -8,
    cursor: 'pointer'
  }
}))

export const ColumnFilters: React.FC = () => {
  const columns = useRoleStore((state) => state.columns)
  const [saving, columnFilters] = useRoleDialogStore((state) => [
    state.saving,
    state.columnFilters
  ])
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      {columnFilters.map((columnFilter, index) => (
        <Grid key={index} item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Autocomplete
                options={columns}
                autoHighlight
                autoComplete
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <Box position="relative" minWidth="auto">
                    <TextField
                      {...params}
                      label="Column"
                      margin="none"
                      className={classes.textField}
                    />

                    <Box
                      className={classes.removeButton}
                      onClick={() => removeColumnFilter(index)}
                    >
                      <Cancel fontSize="small" color="error" />
                    </Box>
                  </Box>
                )}
                onChange={(event: any, newValue: string | null, reason) => {
                  if (newValue) {
                    updateColumnFilter(index, 'column', newValue)
                  } else if (reason === 'clear') {
                    updateColumnFilter(index, 'column', '')
                  }
                }}
                value={columnFilter.column}
              />
            </Grid>
            <Grid item xs>
              <FormField
                label="Access filter"
                value={columnFilter.accessFilter}
                required={false}
                select
                disabled={saving}
                setField={(value) =>
                  updateColumnFilter(index, 'accessFilter', value)
                }
              >
                <MenuItem value="contains">Contains</MenuItem>
              </FormField>
            </Grid>
            <Grid item xs>
              <FormField
                label="Value"
                value={columnFilter.value}
                required={false}
                disabled={saving}
                setField={(value) => updateColumnFilter(index, 'value', value)}
              />
            </Grid>
            <Grid item xs="auto">
              <Box display="flex" flexDirection="column" height="100%">
                <Box flexGrow={0.5}>
                  <Box component="span" fontSize={13} fontWeight={500}>
                    Active
                  </Box>
                </Box>
                <SwitchToggle
                  active={columnFilter.active}
                  toggle={() => toggleColumnFilterActive(index)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <OutlineButton
          primary
          icon={<Add />}
          iconRight
          onClick={addColumnFilter}
        >
          Add column filter
        </OutlineButton>
      </Grid>
    </Grid>
  )
}
