import { Grid } from '@material-ui/core'
import { BigChart } from '@root/components/charts/big-chart'
import { CircularChart } from '@root/components/charts/chart-types/circular-chart'
import { HorizontalBarChart } from '@root/components/charts/chart-types/horizontal-bar-chart'
import { LineChart } from '@root/components/charts/chart-types/line-chart'
import { PieChart } from '@root/components/charts/chart-types/pie-chart'
import { VerticalBarChart } from '@root/components/charts/chart-types/vertical-bar-chart'
import { SmallChart } from '@root/components/charts/small-chart'
import { DataChartModel } from '@shared/models/charts/any-chart'
import React from 'react'
import { Loader } from '../loader'

interface Props {
  chart: DataChartModel
  size?: 4 | 6 | 12
  duplicate?: () => void
  removeFromDashboard?: () => void
  realtimeEdit?: () => void
  schedule?: () => void
  rename?: () => void
  edit?: () => void
  remove?: () => void
  error?: () => void
}

class ErrorBoundary extends React.Component<
  { error: () => void },
  { hasError: boolean }
> {
  constructor(props: { error: () => void }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    this.props.error()
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}

// TODO: Move to dashboard when system is finalized and have an overview component for library
export const ChartRenderer: React.FC<Props> = ({
  chart,
  size,
  duplicate,
  schedule,
  edit,
  rename,
  remove,
  removeFromDashboard,
  realtimeEdit,
  error
}) => {
  return (
    <ErrorBoundary error={error || (() => undefined)}>
      {chart.variant === 'single-value' ? (
        <Grid item xs={size || 4}>
          {chart.data && chart.data.variant === 'single-value' ? (
            <SmallChart
              variant={chart.data.polarity}
              title={chart.name}
              value={chart.data.value}
              pitch={chart.data.pitch}
              benchmark={chart.data.benchmark}
              duplicate={duplicate}
              schedule={schedule}
              rename={rename}
              edit={edit}
              remove={remove}
              removeFromDashboard={removeFromDashboard}
            />
          ) : (
            <Loader />
          )}
        </Grid>
      ) : (
        <Grid item xs={size || 12}>
          <BigChart
            name={chart.name}
            duplicate={duplicate}
            schedule={schedule}
            rename={rename}
            edit={edit}
            remove={remove}
            removeFromDashboard={removeFromDashboard}
            realtimeEdit={realtimeEdit}
          >
            {chart.data ? (
              <>
                {chart.data.variant === 'line' && (
                  <LineChart size={size || 12} data={chart.data} />
                )}
                {chart.data.variant === 'circular' && (
                  <CircularChart size={size || 12} data={chart.data.dataList} />
                )}
                {chart.data.variant === 'pie' && (
                  <PieChart size={size || 12} data={chart.data.dataList} />
                )}
                {chart.data.variant === 'vertical-bar' && (
                  <VerticalBarChart size={size || 12} data={chart.data} />
                )}
                {chart.data.variant === 'horizontal-bar' && (
                  <HorizontalBarChart data={chart.data} />
                )}{' '}
              </>
            ) : (
              <Loader />
            )}
          </BigChart>
        </Grid>
      )}
    </ErrorBoundary>
  )
}
