import { makeStyles, Theme, Typography } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import { useChartImage } from '@root/helpers/chart-image'
import clsx from 'clsx'
import React from 'react'

interface Props {
  variant: 'type' | 'query'
  image: string
  title: string
  selected?: boolean
  click?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  chartType: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      height: 68
    },
    '& h4': {
      fontSize: 16,
      fontWeight: 'normal',
      marginTop: muiTheme.spacing(3),
      maxWidth: '90%',
      textAlign: 'center'
    },
    zIndex: 1,
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: -muiTheme.spacing(2),
      left: 0,
      width: '100%',
      height: `calc(100% + ${muiTheme.spacing(4)}px)`,
      background: theme.palette.blue('lightest'),
      borderRadius: '8px',
      zIndex: -1,
      opacity: 0,
      transition: 'all 200ms ease-in'
    }
  },
  selected: {
    '&::before': {
      opacity: 1
    }
  }
}))

export const ChartSelection: React.FC<Props> = ({
  variant,
  title,
  image,
  selected,
  click
}) => {
  const imageRef = useChartImage(variant, image)
  const styles = useStyles()

  return (
    <div
      className={clsx(styles.chartType, selected && styles.selected)}
      onClick={click}
    >
      <img src={imageRef} alt={title} />
      <Typography variant="h4">{title}</Typography>
    </div>
  )
}
