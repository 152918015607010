import { Avatar, Box, List, makeStyles } from '@material-ui/core'
import { Pause } from '@material-ui/icons'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { reorderChart, toggleChartLink } from '../../../../store/actions'
import { useDashboardStore } from '../../../../store/store'
import { ReorderChart } from './components/reorder-chart'

const useStyles = makeStyles(() => ({
  link: {
    width: 48,
    height: 48,
    transform: 'rotate(0deg)',
    transition: 'background 80ms linear, transform 80ms linear',
    cursor: 'pointer',
    background: theme.palette.dark('lightest'),
    '& svg': {
      width: 32,
      height: 32
    },
    '&:hover': {
      background: theme.palette.dark('lighter')
    }
  },
  linkActive: {
    transform: 'rotate(90deg)',
    background: theme.palette.green(),
    '&:hover': {
      background: theme.palette.green('light')
    }
  }
}))

export const Reorder: React.FC<{}> = () => {
  const charts = useDashboardStore((state) => state.charts)
  const classes = useStyles()

  return (
    <>
      {charts.map((chart, index, array) => (
        <Box key={index} position="relative">
          {index < array.length - 1 && (
            <Box
              position="absolute"
              top={index * 124 + 94}
              left="50%"
              zIndex={1}
              onClick={() => toggleChartLink(chart)}
            >
              <Avatar
                className={clsx(
                  classes.link,
                  chart.linkedWithNext && classes.linkActive
                )}
              >
                <Pause />
              </Avatar>
            </Box>
          )}
        </Box>
      ))}
      <Box maxWidth={800} margin="0 auto">
        <DragDropContext
          onDragEnd={(result) =>
            result.destination &&
            reorderChart(result.source.index, result.destination.index)
          }
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List
                disablePadding
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {charts.map((chart, index, array) => (
                  <>
                    <Draggable
                      key={chart.id}
                      draggableId={chart.id.toString()}
                      index={index}
                    >
                      {(provided2, snapshot2) => (
                        <ReorderChart
                          chart={chart}
                          dragProps={{
                            ...{ ref: provided2.innerRef },
                            ...provided2.dragHandleProps,
                            ...provided2.draggableProps
                          }}
                        />
                      )}
                    </Draggable>
                  </>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  )
}
