import { debounce } from '@material-ui/core'
import {
  createSetDirtyField,
  createSetField,
  createSetPreviousDirtyFields
} from '@root/helpers/store/form'
import { validate } from '@root/helpers/validation'
import { endpoints } from '@root/transport/http/endpoints'
import { emailRules } from '@shared/helpers/validation'
import { RoleModel } from '@shared/models/role/role'
import { MerchantModel } from '@shared/models/user/merchant'
import { ConflictError } from '@shared/transport/http/errors'
import { saveUser, updateUserStatus } from '../../../store/actions'
import { userStoreApi } from '../../../store/store'
import { initialState, userDialogStoreApi } from './store'

const getSelectedUser = () => {
  const { users, selectedUserId } = userStoreApi.getState()

  return users.find((_user) => _user.id === selectedUserId)
}

const getEmailAvailability = debounce(async () => {
  const user = getSelectedUser()
  const { email } = userDialogStoreApi.getState().fields

  if (
    (user && email === user.email) ||
    !validate({ email }, { email: emailRules })
  ) {
    return
  }

  try {
    await endpoints.users.getEmailAvailability.dispatch(email)
    userDialogStoreApi.setState({ emailState: 'available' })
  } catch (e) {
    if (e instanceof ConflictError) {
      userDialogStoreApi.setState({ emailState: 'taken' })
    } else {
      throw e
    }
  }
}, 1000)

export const init = async () => {
  const user = getSelectedUser()

  endpoints.roles.getAll.dispatch().then(({ roles }) =>
    userDialogStoreApi.setState({
      organizationRoles: roles
    })
  )

  if (user) {
    userDialogStoreApi.setState({
      fields: {
        email: user.email,
        singleSignOn: user.singleSignOn
      },
      dirtyFields: {
        email: true,
        singleSignOn: true
      },
      emailState: 'available',
      roles: [...user.roles],
      merchants: [...user.merchants],
      status: user.status
    })
  }
}

export const reset = () => userDialogStoreApi.setState({ ...initialState })
export const setField = createSetField(userDialogStoreApi)
export const setDirtyField = createSetDirtyField(userDialogStoreApi)
export const setPreviousDirtyFields = createSetPreviousDirtyFields(
  userDialogStoreApi,
  ['email', 'singleSignOn']
)

export const resetEmailAvailability = () => {
  userDialogStoreApi.setState({ emailState: 'validating' })
  getEmailAvailability()
}

export const addRole = (role: RoleModel) =>
  userDialogStoreApi.setState({
    roles: [...userDialogStoreApi.getState().roles, role]
  })

export const removeRole = (role: RoleModel) =>
  userDialogStoreApi.setState({
    roles: userDialogStoreApi
      .getState()
      .roles.filter((_role) => _role.id !== role.id)
  })

export const addMerchant = (merchant: MerchantModel) =>
  userDialogStoreApi.setState({
    merchants: [...userDialogStoreApi.getState().merchants, merchant.id]
  })

export const removeMerchant = (merchant: MerchantModel) =>
  userDialogStoreApi.setState({
    merchants: userDialogStoreApi
      .getState()
      .merchants.filter((_merchant) => _merchant !== merchant.id)
  })

export const setStatus = async () => {
  const { selectedUserId } = userStoreApi.getState()
  const status =
    userDialogStoreApi.getState().status === 'active' ? 'inactive' : 'active'
  userDialogStoreApi.setState({ saving: true })

  if (selectedUserId) {
    await endpoints.users.setStatus.dispatch({ id: selectedUserId, status })
    userDialogStoreApi.setState({ status, saving: false })
    updateUserStatus(status)
  }
}

export const save = async () => {
  const {
    fields: { email, singleSignOn },
    roles,
    merchants
  } = userDialogStoreApi.getState()

  userDialogStoreApi.setState({ saving: true })

  saveUser(email, singleSignOn, roles, merchants)
}
