import { DashboardDetailModel } from '@shared/models/dashboard'
import create from 'zustand'
import { ActiveDialog } from './models/active-dialog'

interface DashboardStore {
  viewState: 'fetching' | 'viewing'
  dashboards: Array<DashboardDetailModel>
  selectedDashboardId: number | undefined
  activeDialog: ActiveDialog
}

export const initialState: DashboardStore = {
  viewState: 'fetching',
  dashboards: [],
  selectedDashboardId: undefined,
  activeDialog: 'none'
}

export const [useDashboardStore, dashboardStoreApi] = create<DashboardStore>(
  () => initialState
)
