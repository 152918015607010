import { TransactionDetailModel } from '@shared/models/transactions/transaction-detail'
import create from 'zustand'

interface TransactionDetailStore {
  viewState: 'fetching' | 'viewing'
  transaction: TransactionDetailModel | undefined
}

export const [useTransactionDetailStore, transactionDetailStoreApi] = create<
  TransactionDetailStore
>(() => ({
  viewState: 'fetching',
  transaction: undefined
}))
