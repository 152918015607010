import {
  Checkbox,
  IconButton,
  makeStyles,
  MenuItem,
  Theme,
  Typography
} from '@material-ui/core'
import { GetApp, MoreHoriz } from '@material-ui/icons'
import { DataCell } from '@root/components/data-table/data-cell'
import { DataRow } from '@root/components/data-table/data-row'
import { DataTable } from '@root/components/data-table/data-table'
import { EmptyColumn } from '@root/components/data-table/empty-column'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { OutlineButton } from '@root/components/outline-button'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import { getCsvProps } from '@root/helpers/csv'
import { Scheme } from '@root/pages/transactions/components/scheme'
import { navigate } from 'hookrouter'
import React from 'react'
import { Status } from '../../../components/status'
import { selectTransactions } from '../store/actions'
import {
  useSelectedAll,
  useTransactions,
  useVisibleColumns
} from '../store/selectors'
import { Pagination } from './pagination'
import { ShowHideColumns } from './show-hide-columns'
import { TransactionColumn } from './transaction-column'

const useStyles = makeStyles((muiTheme: Theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: muiTheme.spacing(2, 2.5),
    marginBottom: muiTheme.spacing(0.25),
    borderBottom: `1px solid ${theme.colors.veryLightBlue}`
  },
  checkbox: {
    padding: muiTheme.spacing(0.75)
  },
  overview: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: muiTheme.spacing(2),
    padding: muiTheme.spacing(1.5, 2.5),
    border: `1px solid ${theme.colors.veryLightBlue}`,
    borderLeft: 0,
    borderRight: 0,
    fontWeight: 500
  },
  totalTransactions: {
    marginRight: muiTheme.spacing(),
    color: theme.palette.dark('light'),
    fontWeight: 300
  }
}))

export const TransactionTable = () => {
  const styles = useStyles()
  const visibleColumns = useVisibleColumns()
  const transactions = useTransactions()
  const selectedAll = useSelectedAll()

  return (
    <Paper>
      <div className={styles.actions}>
        <OutlineButton
          primary
          icon={<GetApp />}
          {...getCsvProps(
            [
              visibleColumns.map((column) => column.label),
              ...transactions.map((transaction) =>
                visibleColumns.map((column) => transaction[column.key])
              )
            ],
            'transactions.csv'
          )}
        >
          Export .csv
        </OutlineButton>
        <ShowHideColumns />
      </div>
      <DataTable
        columns={
          <>
            <DataCell padding="none">
              <Checkbox
                checked={selectedAll}
                onChange={() => selectTransactions(...transactions)}
                className={styles.checkbox}
              />
            </DataCell>
            {visibleColumns.map((column, index) => (
              <TransactionColumn
                key={index}
                label={column.label}
                column={column.key}
              />
            ))}
            <EmptyColumn />
          </>
        }
        dataRows={
          <>
            {transactions.map((transaction, index) => (
              <DataRow key={index}>
                <DataCell padding="none">
                  <Checkbox
                    checked={transaction.selected}
                    onChange={() => selectTransactions(transaction)}
                    className={styles.checkbox}
                  />
                </DataCell>
                {transaction.columns.map((column, columnIndex) => (
                  <DataCell key={columnIndex}>
                    {column === 'status' && (
                      <Status status={transaction[column]} />
                    )}
                    {column === 'scheme' && (
                      <Scheme scheme={transaction[column]} />
                    )}
                    {!['status', 'scheme'].includes(column) &&
                      transaction[column]}
                  </DataCell>
                ))}
                <DataCell alignRight>
                  <Dropdown
                    button={
                      <IconButton>
                        <MoreHoriz />
                      </IconButton>
                    }
                  >
                    <MenuItem
                      key={index}
                      onClick={() =>
                        navigate(`/transactions/${transaction.transaction_id}`)
                      }
                    >
                      <Typography variant="inherit">View details</Typography>
                    </MenuItem>
                  </Dropdown>
                </DataCell>
              </DataRow>
            ))}
          </>
        }
      />
      <Typography variant="h4" className={styles.overview}>
        <div>
          <span className={styles.totalTransactions}>Total transactions</span>
          {transactions.length}
        </div>
        <div>
          €{' '}
          {transactions.reduce(
            (total, transaction) =>
              parseFloat(
                transaction.amount_final.toString().replace(/[^0-9.]+/g, '')
              ) + total,
            0
          )}
        </div>
      </Typography>
      <Pagination />
    </Paper>
  )
}
