import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { toggleSignInMethod } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import React from 'react'

const useStyles = makeStyles((muiTheme) => ({
  methodButton: {
    margin: muiTheme.spacing(2, 0, 2)
  }
}))

export const MethodButton: React.FC = () => {
  const [authState, signInMethod] = useAuthStore((state) => [
    state.authState,
    state.signInMethod
  ])
  const classes = useStyles()

  const toggleMethod = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleSignInMethod()
    e.preventDefault()
  }

  return (
    <Button
      type="submit"
      fullWidth
      variant="outlined"
      color="primary"
      onClick={toggleMethod}
      className={classes.methodButton}
      disabled={authState === 'authenticating'}
    >
      {signInMethod === 'password'
        ? 'Sign in with email'
        : 'Sign in with password'}
    </Button>
  )
}
