import { SortDirection } from '@root/pages/team/models/sort-direction'
import { RoleDetailModel } from '@shared/models/role/detail'
import create from 'zustand'
import { ActiveDialog } from '../models/active-dialog'
import { SortColumn } from '../models/sort-column'

interface RoleStore {
  viewState: 'fetching' | 'viewing'
  roles: Array<RoleDetailModel>
  columns: Array<string>
  searchQuery: string
  sortColumn: SortColumn
  sortDirection: SortDirection
  selectedRoleId: number | undefined
  activeDialog: ActiveDialog
}

export const [useRoleStore, roleStoreApi] = create<RoleStore>(() => ({
  viewState: 'fetching',
  roles: [],
  columns: [],
  searchQuery: '',
  sortColumn: 'name',
  sortDirection: 'asc',
  selectedRoleId: undefined,
  activeDialog: 'none'
}))
