import { AuthState } from '@root/models/auth-state'
import create from 'zustand'

interface AuthStore {
  authState: AuthState
  signInMethod: 'email' | 'password'
  email: string
  password: string
  code: string
  me: {
    admin: boolean
    organization: string
  }
}

export const [useAuthStore, authStoreApi] = create<AuthStore>(() => ({
  authState: 'verifying',
  signInMethod: 'email',
  email: '',
  password: '',
  code: '',
  me: {
    admin: false,
    organization: ''
  }
}))
