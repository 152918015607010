import { makeStyles, TableCell, Theme } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { theme } from '@root/constants/theme'
import React from 'react'
import { SortDirection } from '../../models/sort-direction'

interface Props {
  label: string
  column: string
  sortColumn: string
  sortDirection: SortDirection
  width?: string
  sort: (column: any) => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    fontSize: 16,
    color: theme.palette.dark('light'),
    border: 0,
    paddingBottom: muiTheme.spacing(0.75),
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  },
  inner: {
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    marginRight: muiTheme.spacing(0.5)
  },
  sortIcon: {
    minWidth: 20,
    height: 20
  }
}))

export const Column: React.FC<Props> = ({
  label,
  column,
  sortColumn,
  sortDirection,
  width,
  sort
}) => {
  const styles = useStyles()

  return (
    <TableCell
      className={styles.root}
      onClick={() => column && sort && sort(column)}
      width={width}
    >
      {column && (
        <div className={styles.inner}>
          <span className={styles.label}>{label}</span>
          <span className={styles.sortIcon}>
            {sortColumn === column && sortDirection === 'asc' && (
              <KeyboardArrowDown fontSize="small" />
            )}
            {sortColumn === column && sortDirection === 'desc' && (
              <KeyboardArrowUp fontSize="small" />
            )}
          </span>
        </div>
      )}
    </TableCell>
  )
}
