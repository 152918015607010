import { DashboardColor } from '@shared/models/dashboard'
import { theme } from './theme'

export const dashboardColors: Array<{
  color: DashboardColor
  code: string
}> = [
  { color: 'blue', code: theme.palette.blue('light') },
  { color: 'green', code: theme.palette.green('light') },
  { color: 'orange', code: theme.palette.orange('light') },
  { color: 'red', code: theme.palette.red('light') },
  { color: 'turquiose', code: theme.palette.turquiose('light') },
  { color: 'dark', code: theme.palette.dark('lighter') }
]
