"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLinkCharts = (charts, selectedChart) => {
    let linkCharts = charts.map((chart) => chart.id === selectedChart.id ? { ...chart, linkedWithNext: true } : chart);
    let iteratedAllCharts = false;
    dance: while (!iteratedAllCharts) {
        const linkedCharts = [];
        for (const chart of linkCharts) {
            if (chart.linkedWithNext) {
                linkedCharts.push(chart);
            }
            else {
                linkedCharts.splice(0, linkedCharts.length);
            }
            if (linkedCharts.length >=
                (linkedCharts.every((chart) => chart.method === 'advanced') &&
                    linkCharts.every((chart, index, array) => !linkedCharts.find((linkedChart) => linkedChart.id === chart.id) ||
                        (array[index + 1] && array[index + 1].method === 'advanced')) &&
                    chart.method === 'advanced'
                    ? 3
                    : 2)) {
                const unlinkChart = linkedCharts.find((chart) => chart.id !== selectedChart.id);
                if (unlinkChart) {
                    linkCharts = linkCharts.map((chart) => chart.id === unlinkChart.id
                        ? { ...chart, linkedWithNext: false }
                        : chart);
                }
                continue dance;
            }
        }
        iteratedAllCharts = true;
    }
    return linkCharts;
};
