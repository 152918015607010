import { makeStyles, TableRow, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  alignRight?: boolean
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    '&:nth-child(even)': {
      background: theme.colors.tableGrey
    }
  }
}))

export const DataRow: React.FC<Props> = ({ alignRight, children }) => {
  const styles = useStyles()

  return <TableRow className={styles.root}>{children}</TableRow>
}
