import { List, makeStyles } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ActionButton } from '@root/components/action-button'
import { Loader } from '@root/components/loader'
import { dashboardIcons } from '@root/constants/dashboard-icons'
import React, { useEffect } from 'react'
import { DeleteDialog } from './components/delete-dialog'
import { RenameDialog } from './components/rename-dialog'
import { SaveDialog } from './components/save-dialog/save-dialog'
import {
  fetchDashboards,
  openDialog,
  reset,
  reorderChart
} from './store/actions'
import { useDashboardStore } from './store/store'
import { Dashboard } from './components/dashboard'

const useStyles = makeStyles((muiTheme) => ({
  dashboardList: {
    margin: '0 auto',
    maxWidth: 680
  }
}))

export const Dashboards = () => {
  const [viewState, dashboards] = useDashboardStore((state) => [
    state.viewState,
    state.dashboards
  ])
  const classes = useStyles()

  useEffect(() => {
    fetchDashboards()

    return reset
  }, [])

  return (
    <>
      {viewState === 'fetching' && <Loader />}
      {viewState === 'viewing' && (
        <DragDropContext
          onDragEnd={(result) =>
            result.destination &&
            reorderChart(result.source.index, result.destination.index)
          }
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List
                disablePadding
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.dashboardList}
              >
                {dashboards.map((dashboard, index) => {
                  const TypeIcon = dashboardIcons.find(
                    (typeIcon) => typeIcon.type === dashboard.type
                  )?.icon

                  if (!TypeIcon) {
                    throw new Error()
                  }

                  return (
                    <Draggable
                      key={dashboard.id}
                      draggableId={dashboard.id.toString()}
                      index={index}
                    >
                      {(provided2, snapshot2) => (
                        <Dashboard
                          dashboard={dashboard}
                          dragProps={{
                            ...{ ref: provided2.innerRef },
                            ...provided2.dragHandleProps,
                            ...provided2.draggableProps
                          }}
                        />
                      )}
                    </Draggable>
                  )
                })}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <ActionButton click={() => openDialog('add')} />
      <DeleteDialog />
      <RenameDialog />
      <SaveDialog />
    </>
  )
}
