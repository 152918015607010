import { endpoints } from '@root/transport/http/endpoints'
import { implementationGuideStoreApi, initialState } from './store'
import { StepModel } from '../models/step'

const { getState, setState } = implementationGuideStoreApi

const getPreviousStep = (step: StepModel): StepModel => {
  switch (step) {
    case 'synchronize':
      return 'access'
    case 'accounts':
      return 'synchronize'
    case 'ready':
      return 'accounts'
  }

  throw new Error(`Could not get the previous step of ${step}`)
}

const getNextStep = (step: StepModel): StepModel => {
  switch (step) {
    case 'access':
      return 'synchronize'
    case 'synchronize':
      return 'accounts'
    case 'accounts':
      return 'ready'
  }

  throw new Error(`Could not get the next step of ${step}`)
}

export const reset = () => setState({ ...initialState })
export const fetchSynchronizeTables = async () => {
  const {
    synchronizeTables,
    bigQueryTables
  } = await endpoints.implementationGuide.synchronizeTables.dispatch()

  setState({
    synchronizeTables,
    bigQueryTables
  })
}

export const setStep = (step: StepModel) => setState({ step })
export const previousStep = () =>
  setState({ step: getPreviousStep(getState().step) })

export const nextStep = () => setState({ step: getNextStep(getState().step) })
