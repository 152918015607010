import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import React from 'react'
import { Status } from '../../../components/status'
import { useTransactionDetailStore } from '../store/store'
import { Flag } from './flag'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    height: 54
  },
  inner: {
    width: '100%',
    height: '100%'
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRight: `1px solid ${theme.colors.veryLightBlue}`,
    padding: muiTheme.spacing(0, 3)
  },
  category: {
    color: theme.palette.dark('light'),
    fontWeight: 300,
    marginRight: muiTheme.spacing()
  },
  value: {
    color: theme.palette.dark('regular'),
    fontWeight: 300
  },
  country: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: muiTheme.spacing(3)
  }
}))

export const Summary = () => {
  const styles = useStyles()
  /* eslint-disable-next-line @typescript-eslint/camelcase */
  const [amount_final, status, country] = useTransactionDetailStore((state) => [
    state.transaction?.amount_final,
    state.transaction?.status,
    state.transaction?.cardDetails.country
  ])

  return (
    <Paper className={styles.root}>
      <Grid container className={styles.inner}>
        <Grid item className={styles.container}>
          <Typography variant="h4" className={styles.category}>
            Amount
          </Typography>
          <Typography variant="h4" className={styles.value}>
            {/* eslint-disable-next-line @typescript-eslint/camelcase */}
            {amount_final}
          </Typography>
        </Grid>
        <Grid item className={styles.container}>
          <Typography variant="h4" className={styles.category}>
            Processor
          </Typography>
          <Typography variant="h4" className={styles.value}>
            ???
          </Typography>
        </Grid>
        <Grid item className={styles.container}>
          <Typography variant="h4" className={styles.category}>
            Status
          </Typography>
          <Typography variant="h4" className={styles.value}>
            {status && <Status status={status} />}
          </Typography>
        </Grid>
        <Grid item className={styles.country}>
          {country && <Flag country={country} />}
        </Grid>
      </Grid>
    </Paper>
  )
}
