import { Grid, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'
import {
  nextPage,
  previousPage,
  setItemsPerPage,
  setPage
} from '../store/actions'
import { useNextPage, usePages, usePreviousPage } from '../store/selectors'
import { useTransactionStore } from '../store/store'

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: muiTheme.spacing(1.5, 2.5),
    fontSize: 18,
    color: theme.palette.dark('light')
  },
  select: {
    fontSize: 18,
    margin: muiTheme.spacing(0.25, 0.5, 0, 1),
    '&:before': {
      borderBottom: 0
    }
  },
  itemsPerPage: {
    display: 'flex',
    alignItems: 'center'
  },
  page: {
    display: 'flex',
    alignItems: 'center'
  },
  pageSelect: {
    '& .MuiSelect-root': {
      paddingRight: muiTheme.spacing(2)
    }
  },
  pageNavigation: {
    marginLeft: muiTheme.spacing(6)
  }
}))

export const Pagination = () => {
  const styles = useStyles()
  const [itemsPerPage, page] = useTransactionStore((state) => [
    state.itemsPerPage,
    state.page
  ])
  const pages = usePages()
  const canNextPage = useNextPage()
  const canPreviousPage = usePreviousPage()

  return (
    <div className={styles.root}>
      <div className={styles.itemsPerPage}>
        <span>Items per page</span>
        <Select
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(e.target.value as number)}
          className={styles.select}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </div>
      <div className={styles.page}>
        <div>
          <span>Page</span>
          <Select
            value={page}
            onChange={(e) => setPage(e.target.value as number)}
            className={clsx(styles.select, styles.pageSelect)}
          >
            {pages.map((_page, index) => (
              <MenuItem key={index} value={_page}>
                {_page}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.pageNavigation}>
          <Grid container spacing={2}>
            <Grid item>
              <IconButton
                icon={<NavigateBefore />}
                secondary
                small
                disabled={!canPreviousPage}
                onClick={previousPage}
              />
            </Grid>
            <Grid item>
              <IconButton
                icon={<NavigateNext />}
                secondary
                small
                disabled={!canNextPage}
                onClick={nextPage}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
