import { SynchronizeTable } from '@shared/models/synchronize-table'
import create from 'zustand'
import { StepModel } from '../models/step'

export interface ImplementationGuideState {
  synchronizeTables: Array<SynchronizeTable>
  bigQueryTables: Array<SynchronizeTable>
  step: StepModel
}

export const initialState: ImplementationGuideState = {
  synchronizeTables: [],
  bigQueryTables: [],
  step: 'access'
}

export const [
  useImplementationGuideStore,
  implementationGuideStoreApi
] = create<ImplementationGuideState>(() => initialState)
