import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { GridOn, Save } from '@material-ui/icons'
import { Button } from '@root/components/button'
import { ChartRenderer } from '@root/components/charts/chart-renderer'
import { theme } from '@root/constants/theme'
import { getCsvProps } from '@root/helpers/csv'
import {
  DataChartModel,
  PercentageData,
  TimelineData
} from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import React from 'react'
import { AddToDashboard } from '../components/add-to-dashboard'
import { setRenderError } from '../store/actions'
import { useChart } from '../store/selectors'
import { useChartBuilderStore } from '../store/store'

interface Props {
  type: 'dashboard' | 'library'
  dashboards: Array<DashboardModel>
  initialDashboardId?: number
  save: (chart: DataChartModel, dashboardId?: number) => void
}

const useStyles = makeStyles(() => ({
  excelBox: {
    textDecoration: 'none',
    '&:hover': {
      background: theme.colors.veryLightBlue,
      cursor: 'pointer'
    }
  },
  text: {
    color: theme.palette.dark('lighter')
  },
  dashboards: {
    minWidth: 250
  }
}))

// TODO: Refine
const dataToRows = (data: PercentageData | TimelineData): Array<Array<any>> => {
  const timelineData = data as TimelineData
  if (timelineData.labels) {
    return [
      ['Date', ...timelineData.labels.map((label) => label.name)],
      ...timelineData.dataList.map((row) => [
        row.date,
        ...timelineData.labels.map((label) => row[label.key])
      ])
    ]
  }

  const percentageData = data as PercentageData
  return percentageData.dataList.map((row) => [row.label, row.count])
}

export const ChartFinalized: React.FC<Props> = ({
  type,
  dashboards,
  initialDashboardId,
  save
}) => {
  const classes = useStyles()
  const [viewState, chartRenderError, data] = useChartBuilderStore((store) => [
    store.viewState,
    store.chartRenderError,
    store.data,
    store.types
  ])
  const chart = useChart()

  return (
    <div style={{ width: '100%' }}>
      {!chart.data && <p>Retrieving data ...</p>}
      {chart.data && (
        <Grid container justify="center">
          {chart && !chartRenderError && (
            <ChartRenderer chart={chart} error={setRenderError} />
          )}
          {chart && chartRenderError && (
            <Box
              component="a"
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={2}
              borderRadius={4}
              className={classes.excelBox}
              {...getCsvProps(dataToRows(data), 'advanced_custom_report.csv')}
            >
              <GridOn
                style={{
                  fontSize: 120,
                  color: theme.palette.green(),
                  marginBottom: 24
                }}
              />
              <Typography variant="h3" className={classes.text}>
                Advanced Custom Report
              </Typography>
              <Typography variant="subtitle2" className={classes.text}>
                Click here to continue in Excel!
              </Typography>
            </Box>
          )}
        </Grid>
      )}
      {chart && !chartRenderError && (
        <Box pt={4}>
          <Grid
            container
            justify={
              chart?.data?.variant === 'single-value' ? 'center' : 'flex-end'
            }
            spacing={2}
          >
            {type === 'library' && (
              <Grid item>
                <Button
                  secondary
                  icon={<Save />}
                  disabled={viewState === 'saving'}
                  onClick={() => save(chart)}
                >
                  Save to library
                </Button>
              </Grid>
            )}
            {type === 'dashboard' && (
              <AddToDashboard
                dashboards={dashboards}
                initialDashboardId={initialDashboardId}
                disabled={viewState === 'saving'}
                add={(_dashboardId) => save(chart, _dashboardId)}
              />
            )}
          </Grid>
        </Box>
      )}
    </div>
  )
}
