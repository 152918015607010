interface State<Fields extends Record<string, any>> {
  fields: Fields
  dirtyFields: Record<keyof Fields, boolean>
}

interface StoreApi<Fields extends Record<string, any>> {
  getState: () => State<Fields>
  setState: (state: Partial<State<Fields>>) => void
}

export interface FormState<Field extends string> {
  fields: Record<Field, any>
  dirtyFields: Record<Field, boolean>
}

export const createSetField = <Fields extends Record<string, any>>(
  api: StoreApi<Fields>
) => (fields: Partial<Fields>) =>
  api.setState({ fields: { ...api.getState().fields, ...fields } })

export const createSetDirtyField = <Fields extends Record<string, any>>(
  api: StoreApi<Fields>
) => (field: keyof Fields) =>
  api.getState().fields[field] &&
  api.setState({
    dirtyFields: { ...api.getState().dirtyFields, [field]: true }
  })

export const createSetPreviousDirtyFields = <
  Fields extends Record<string, any>
>(
  api: StoreApi<Fields>,
  fieldOrder: Array<keyof Fields>
) => (field: keyof Fields) =>
  api.setState({
    dirtyFields: {
      ...api.getState().dirtyFields,
      ...fieldOrder
        .slice(0, fieldOrder.indexOf(field))
        .reduce(
          (fields, _field) => ({ ...fields, [_field]: true }),
          {} as Partial<Record<keyof Fields, true>>
        )
    }
  })
