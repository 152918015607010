import { Breadcrumbs, Grid, Link, makeStyles, Theme } from '@material-ui/core'
import { ArrowForward, Check, Delete, Save } from '@material-ui/icons'
import { OutlineButton } from '@root/components/outline-button'
import { theme } from '@root/constants/theme'
import { DataChartModel } from '@shared/models/charts/any-chart'
import clsx from 'clsx'
import React from 'react'
import { Step } from '../models/step'
import { gotoStep } from '../store/actions'
import { useChart } from '../store/selectors'

interface Props {
  currentStep: Step
  mayManage: boolean
  save: (chart: DataChartModel) => void
  remove?: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: muiTheme.spacing(0.75, 2.25),
    borderBottom: `1px solid ${theme.colors.veryLightBlue}`
  },
  breadcrumbs: {
    '& .MuiBreadcrumbs-separator': {
      marginLeft: muiTheme.spacing(3),
      marginRight: muiTheme.spacing(3)
    },
    '& .MuiBreadcrumbs-li a': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.dark,
      opacity: 0.6,
      lineHeight: '40px',
      textDecoration: 'none'
    }
  },
  activeCrumb: {
    opacity: '1 !important'
  },
  completedCrumb: {
    color: `${theme.palette.green()} !important`,
    '& div': {
      backgroundColor: `${theme.palette.green()} !important`
    },
    '& svg': {
      display: 'block !important'
    },
    cursor: 'pointer'
  },
  crumbCircle: {
    width: 14,
    height: 14,
    border: '1px solid',
    borderRadius: '50%',
    marginRight: muiTheme.spacing(0.75),
    marginBottom: muiTheme.spacing(0.25),
    '& svg': {
      display: 'none',
      width: 12,
      height: 12,
      color: '#fff'
    }
  }
}))

const steps: Array<{ type: Step; label: string }> = [
  { type: 'what-to-see', label: 'What to see' },
  { type: 'filters', label: 'Filters' },
  { type: 'select-chart', label: 'Select chart' },
  { type: 'chart-finalized', label: 'Chart finalized' }
]

export const Navigation: React.FC<Props> = ({
  currentStep,
  mayManage,
  remove,
  save
}) => {
  const styles = useStyles()
  const chart = useChart()

  return (
    <nav className={styles.navigation}>
      <Breadcrumbs
        separator={<ArrowForward fontSize="small" />}
        className={styles.breadcrumbs}
      >
        {steps.map((step, index, array) => {
          const completedStep = array
            .slice(index)
            .map(({ type }) => type)
            .includes(currentStep)

          return (
            <Link
              key={index}
              color="textPrimary"
              className={clsx(
                currentStep === step.type && styles.activeCrumb,
                completedStep && styles.completedCrumb
              )}
              onClick={() => completedStep && gotoStep(step.type)}
            >
              <div className={styles.crumbCircle}>
                <Check fontSize="small" />
              </div>
              <span>{step.label}</span>
            </Link>
          )
        })}
      </Breadcrumbs>
      <div>
        <Grid container spacing={4}>
          <Grid item>
            <OutlineButton
              primary
              icon={<Save />}
              iconRight
              disabled={!mayManage}
              onClick={() => save(chart)}
            >
              Save
            </OutlineButton>
          </Grid>
          {remove && (
            <Grid item>
              <OutlineButton
                primary
                icon={<Delete />}
                iconRight
                disabled={!mayManage}
                onClick={remove}
              >
                Delete
              </OutlineButton>
            </Grid>
          )}
        </Grid>
      </div>
    </nav>
  )
}
