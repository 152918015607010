import { getError } from '@shared/transport/http/errors'
import axios from 'axios'

const baseUrl = process.env.REACT_APP_SERVER_ADDRESS

export async function dispatch(payload: any) {
  return await request(Reflect.getMetadata('route', this), payload)
}

export const request = async (route: string, payload: any) => {
  try {
    const response = await axios.request({
      method: 'post',
      url: `${baseUrl}${route}`,
      headers: {
        Authorization: localStorage.getItem('authToken')
      },
      data: { payload }
    })

    return response.data
  } catch (e) {
    if (e?.response?.status) {
      throw new (getError(e.response.status))()
    }

    throw e
  }
}
