import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { NavigateBefore } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import React from 'react'

interface Props {
  title: string
  subTitle: string
  actionButton?: JSX.Element
  goBack: () => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  overview: {
    marginBottom: muiTheme.spacing(2)
  },
  titleContainer: {
    textAlign: 'center'
  },
  chartName: {
    opacity: 0.6
  },
  action: {
    textAlign: 'right'
  }
}))

export const PageOverview: React.FC<Props> = ({
  title,
  subTitle,
  actionButton,
  goBack
}) => {
  const styles = useStyles()

  return (
    <Grid container className={styles.overview}>
      <Grid item xs={4}>
        <IconButton icon={<NavigateBefore />} primary medium onClick={goBack} />
      </Grid>
      <Grid item xs={4} className={styles.titleContainer}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2" className={styles.chartName}>
          {subTitle}
        </Typography>
      </Grid>
      <Grid item xs={4} className={styles.action}>
        {actionButton}
      </Grid>
    </Grid>
  )
}
