import { debounce } from '@material-ui/core'
import { endpoints } from '@root/transport/http/endpoints'
import { getDateString } from '@shared/constants/date-string'
import { DataChartModel } from '@shared/models/charts/any-chart'
import { FilterComparator } from '@shared/models/charts/filter-comparator'
import { ChartFromDateType } from '@shared/models/charts/from-date-type'
import { endOfMonth, startOfMonth } from 'date-fns'
import { initialState, realtimeEditorStoreApi } from './store'

const { getState, setState } = realtimeEditorStoreApi

const getChart = (): DataChartModel => {
  const {
    id,
    name,
    variant,
    queryId,
    advancedQueryId,
    typeId,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data
  } = getState()

  return {
    id: id || 0,
    name,
    method: 'regular',
    variant,
    queryId,
    advancedQueryId,
    typeId,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    customQuery: '',
    filters: filters.filter((filter) => filter.column && filter.value),
    data
  }
}

export const reset = () => setState({ ...initialState })
export const getChartData = debounce(async () => {
  setState({ data: undefined })
  setState({
    data: await endpoints.charts.get.data.dispatch(getChart())
  })
}, 1000)

export const fetchFilters = async () => {
  const {
    filterColumns
  } = await endpoints.charts.getChartBuilderData.dispatch()
  setState({
    viewState: 'viewing',
    filterColumns
  })

  if (!getState().data) {
    getChartData()
  }
}

export const setChart = (chart: DataChartModel | undefined) => {
  if (chart) {
    setState({ ...chart })
  }
}

export const setFromDateType = (fromDateType: ChartFromDateType) => {
  setState({
    fromDateType,
    fromDateValue:
      fromDateType === 'exact-date'
        ? getDateString(startOfMonth(new Date()))
        : '30',
    toDate:
      fromDateType === 'exact-date' ? getDateString(endOfMonth(new Date())) : ''
  })

  getChartData()
}

export const setFromDateValue = (fromDateValue: string) => {
  setState({ fromDateValue })
  getChartData()
}

export const setToDate = (toDate: string) => {
  setState({ toDate })
  getChartData()
}

export const setTop = (top: number) => {
  setState({ top })
  getChartData()
}

export const addFilter = () =>
  setState({
    filters: [
      ...getState().filters,
      { comparator: 'equals', column: '', value: '' }
    ]
  })

export const setFilterColumn = (filterIndex: number, column: string) =>
  setState({
    filters: realtimeEditorStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, column } : filter
      )
  })

export const setFilterComparator = (
  filterIndex: number,
  comparator: FilterComparator
) =>
  setState({
    filters: realtimeEditorStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, comparator } : filter
      )
  })

export const setFilterValue = (filterIndex: number, value: string) => {
  setState({
    filters: realtimeEditorStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, value } : filter
      )
  })

  getChartData()
}

export const removeFilter = (filterIndex: number) =>
  setState({
    filters: realtimeEditorStoreApi
      .getState()
      .filters.filter((filter, index) => index !== filterIndex)
  })

export const setRenderError = () =>
  setState({
    chartRenderError: true
  })

export const setViewing = () => setState({ viewState: 'viewing' })
export const setNaming = () => setState({ viewState: 'naming' })
export const setSaving = () => setState({ viewState: 'saving' })
