import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useAuthStore } from '@root/store/auth/store'
import React from 'react'

const useStyles = makeStyles((muiTheme) => ({
  submit: {
    margin: muiTheme.spacing(3, 0, 0)
  }
}))

export const SignInButton: React.FC = () => {
  const authState = useAuthStore((state) => state.authState)
  const classes = useStyles()

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={authState === 'authenticating'}
    >
      Sign In
    </Button>
  )
}
