import create from 'zustand'

export interface CommonState {
  error: boolean
}

export const initialState: CommonState = {
  error: false
}

export const [useCommonStore, commonStoreApi] = create<CommonState>(
  () => initialState
)
