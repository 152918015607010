import { filterFields, filterRules } from '@shared/helpers/validation'
import Validator from 'validatorjs'

export const getValidationErrors = <Fields extends { [key: string]: any }>(
  fields: Fields,
  dirtyFields: Record<keyof Fields, boolean>,
  rules: Record<keyof Fields, string>
): Record<keyof Fields, string> => {
  const validator = new Validator(
    filterFields(fields, rules),
    filterRules(rules)
  )
  validator.check()

  return Object.keys(fields).reduce(
    (errors, field) => ({
      ...errors,
      [field]: (dirtyFields[field] && validator.errors.first(field)) || ''
    }),
    { ...fields }
  )
}

export const validate = <Fields extends { [key: string]: any }>(
  fields: Fields,
  rules: Record<keyof Fields, string>
): boolean => {
  const validator = new Validator(
    filterFields(fields, rules),
    filterRules(rules)
  )

  return Boolean(validator.passes())
}
