import { SortDirection } from '@root/pages/team/models/sort-direction'
import { FilterModel } from '@shared/models/transactions/filter'
import { SortColumn } from '@shared/models/transactions/sort-column'
import { TransactionModel } from '@shared/models/transactions/transaction'
import { TransactionSearchModel } from '@shared/models/transactions/transaction-search'
import create from 'zustand'
import { columns } from '../constants/columns'

interface TransactionStore {
  viewState: 'fetching' | 'viewing'
  transactions: Array<TransactionModel>
  selectedTransactions: Array<string>
  filters: Array<FilterModel>
  selectedFilterColumn: string | undefined
  savedSearches: Array<TransactionSearchModel>
  newSavedSearchFilters: Array<FilterModel>
  visibleColumns: Array<SortColumn>
  sortColumn: SortColumn
  sortDirection: SortDirection
  itemsPerPage: number
  page: number
}

export const [useTransactionStore, transactionStoreApi] = create<
  TransactionStore
>(() => ({
  viewState: 'fetching',
  transactions: [],
  selectedTransactions: [],
  filters: [],
  selectedFilterColumn: undefined,
  savedSearches: [],
  newSavedSearchFilters: [],
  visibleColumns: columns.map((column) => column.key),
  sortColumn: 'transaction_id',
  sortDirection: 'asc',
  itemsPerPage: 10,
  page: 1
}))
