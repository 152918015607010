import { makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import { StatusType } from '@shared/models/transactions/status-type'
import clsx from 'clsx'
import React from 'react'

interface Props {
  status: StatusType
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    width: 72,
    padding: 3,
    borderRadius: 4,
    fontSize: 10,
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  completed: {
    background: theme.palette.green()
  },
  failed: {
    background: theme.palette.red()
  },
  pending: {
    background: theme.palette.orange()
  }
}))

export const Status: React.FC<Props> = ({ status }) => {
  const styles = useStyles()

  return <div className={clsx(styles.root, styles[status])}>{status}</div>
}
