import { endpoints } from '@root/transport/http/endpoints'
import { UnauthorizedError } from '@shared/transport/http/errors'
import { authStoreApi } from './store'

const { getState, setState } = authStoreApi

export const setEmail = (email: string) => setState({ email })
export const setPassword = (password: string) => setState({ password })
export const setCode = (code: string) => setState({ code })

export const getMe = async () => {
  try {
    const { admin, organization } = await endpoints.users.getMe.dispatch()
    setState({
      authState: 'authenticated',
      me: { admin, organization }
    })
  } catch (e) {
    setState({ authState: 'unauthenticated' })
  }
}

export const toggleSignInMethod = () =>
  setState({
    signInMethod: getState().signInMethod === 'email' ? 'password' : 'email'
  })

export const authenticateSessionId = async (sessionId: string) => {
  try {
    const token = await endpoints.users.sessionLogin.dispatch(sessionId)
    localStorage.setItem('authToken', token)

    window.location.assign('/')
  } catch (e) {
    if (e instanceof UnauthorizedError) {
      setState({ authState: 'invalid-link' })
    } else {
      setState({ authState: 'unauthenticated' })
      throw e
    }
  }
}

export const goBack = () =>
  setState({ authState: 'unauthenticated', email: '', password: '' })

export const authenticate = async () => {
  const { email, password, signInMethod } = authStoreApi.getState()

  setState({ authState: 'authenticating' })

  if (signInMethod === 'email') {
    const sessionId = await endpoints.users.emailLogin.dispatch(email)

    console.log(sessionId)

    setState({ authState: 'sign-in-email' })
  } else {
    try {
      const token = await endpoints.users.passwordLogin.dispatch({
        email,
        password
      })
      localStorage.setItem('authToken', token)

      setState({ authState: 'authenticated' })
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        setState({
          authState: 'wrong-details',
          email: '',
          password: ''
        })
      } else {
        setState({ authState: 'unauthenticated' })
        throw e
      }
    }
  }
}

export const unauthenticate = async () => {
  localStorage.setItem('authToken', '')
  setState({
    authState: 'unauthenticated'
  })
}
