import { Button as MuiButton, makeStyles, Theme } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import clsx from 'clsx'
import React from 'react'

interface Props {
  primary?: boolean
  secondary?: boolean
  white?: boolean
  large?: boolean
  disabled?: boolean
  icon?: JSX.Element
  iconRight?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  root: {
    padding: muiTheme.spacing(1.5, 2),
    color: 'white',
    borderRadius: 3,
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '18px',
    fontWeight: 'bold',
    '& .MuiButton-endIcon > *:first-child': {
      fontSize: 18
    }
  },
  primary: {
    color: theme.colors.outlinedBlue,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.colors.darkOutlinedBlue
    }
  },
  secondary: {
    color: theme.palette.dark('lighter'),
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.dark('light')
    }
  },
  white: {
    color: theme.palette.white(),
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.white()
    }
  },
  large: {
    fontSize: 18
  },
  icon: {
    padding: 0
  }
}))

export const OutlineButton: React.FC<Props> = ({
  primary,
  secondary,
  white,
  large,
  disabled,
  icon,
  iconRight,
  onClick,
  children,
  ...props
}) => {
  const styles = useStyles()
  const obj:
    | { onClick: (e: React.MouseEvent<HTMLButtonElement>) => void }
    | {} = { ...{ onClick } }

  return (
    <MuiButton
      component={onClick ? 'button' : 'a'}
      variant="text"
      disableRipple
      disabled={disabled}
      startIcon={!iconRight && icon}
      endIcon={iconRight && icon}
      className={clsx(
        styles.root,
        primary && styles.primary,
        secondary && styles.secondary,
        white && styles.white,
        large && styles.large,
        icon && styles.icon
      )}
      {...obj}
      {...props}
    >
      {children}
    </MuiButton>
  )
}
