import { ChartTypeModel } from '@shared/models/charts/chart-type'
import { ChartTypeVariant } from '@shared/models/charts/chart-type-variant'
import { FilterModel } from '@shared/models/charts/filter'
import { ChartFromDateType } from '@shared/models/charts/from-date-type'
import create from 'zustand'

export interface RealtimeEditorState {
  viewState: 'viewing' | 'fetching' | 'naming' | 'saving'
  types: Array<ChartTypeModel>
  filterColumns: Array<string>
  id: number | undefined
  name: string
  variant: ChartTypeVariant
  queryId: number
  advancedQueryId: number
  typeId: number
  fromDateType: ChartFromDateType
  fromDateValue: string
  toDate: string
  top: number
  filters: Array<FilterModel>
  data: any
  chartRenderError: boolean
}

export const initialState: RealtimeEditorState = {
  viewState: 'fetching',
  types: [],
  filterColumns: [],
  id: undefined,
  name: '',
  variant: 'circular',
  queryId: 0,
  advancedQueryId: 0,
  typeId: 0,
  fromDateType: 'last-days',
  fromDateValue: '30',
  toDate: '',
  top: 100,
  filters: [],
  data: undefined,
  chartRenderError: false
}

export const [useRealtimeEditorStore, realtimeEditorStoreApi] = create<
  RealtimeEditorState
>(() => initialState)
