import {
  makeStyles,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Theme
} from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

type TabSize = 'mini' | 'small' | 'large'

interface Props {
  tabs: Array<{
    label: string
    value: string
  }>
  currentTab: string
  size: TabSize
  scrollable?: boolean
  component?: (value: string, active: boolean) => JSX.Element
  setTab: (value: string) => void
}

const useStyles = makeStyles((muiTheme: Theme) => ({
  tabsLarge: {
    height: muiTheme.spacing(8)
  },
  tabsSmall: {
    height: muiTheme.spacing(6)
  },
  tabsFlexContainer: {
    height: '100%'
  },
  tabsIndicatorLarge: {
    backgroundColor: theme.palette.blue(),
    borderRadius: 2,
    height: 4
  },
  tabsIndicatorSmall: {
    backgroundColor: theme.palette.blue(),
    borderRadius: 2,
    height: 3
  },
  tabLarge: {
    margin: '0 25px',
    padding: 0,
    minWidth: 0,
    fontSize: 18,
    textTransform: 'none'
  },
  tabSmall: {
    margin: '0 20px',
    padding: 0,
    minWidth: 0,
    fontSize: 15,
    textTransform: 'none'
  },
  tabMini: {
    margin: '0 8px',
    padding: 0,
    minWidth: 0,
    fontSize: 15,
    textTransform: 'none'
  },
  tabTextColorInherit: {
    opacity: 0.6
  },
  tabTextColorFull: {
    opacity: 1
  }
}))

const invisibleTab = '#invisible-tab'

export const Tabs: React.FC<Props> = ({
  tabs,
  currentTab,
  scrollable,
  size,
  component,
  setTab
}) => {
  const classes = useStyles()
  const selectedTab = tabs.find((tab) => tab.value === currentTab)
    ? currentTab
    : invisibleTab

  const getTabsClass = (_size: TabSize): string => {
    switch (_size) {
      case 'mini':
      case 'small':
        return classes.tabsSmall
      case 'large':
        return classes.tabsLarge
    }
  }

  const getTabClass = (_size: TabSize): string => {
    switch (_size) {
      case 'mini':
        return classes.tabMini
      case 'small':
        return classes.tabSmall
      case 'large':
        return classes.tabLarge
    }
  }

  return (
    <MuiTabs
      value={selectedTab}
      variant={scrollable ? 'scrollable' : 'standard'}
      onChange={(e, value) => setTab(value)}
      classes={{
        root: getTabsClass(size),
        flexContainer: classes.tabsFlexContainer,
        indicator:
          size === 'large'
            ? classes.tabsIndicatorLarge
            : classes.tabsIndicatorSmall
      }}
    >
      <MuiTab
        disableRipple={true}
        value={invisibleTab}
        style={{ display: 'none' }}
      />
      {tabs.map((tab, index) => (
        <MuiTab
          key={index}
          disableRipple={true}
          label={
            component
              ? component(tab.value, currentTab === tab.value)
              : tab.label
          }
          value={tab.value}
          classes={{
            root: getTabClass(size),
            textColorInherit: component
              ? classes.tabTextColorFull
              : classes.tabTextColorInherit
          }}
        />
      ))}
    </MuiTabs>
  )
}
