import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Button } from '@root/components/button'
import { DashboardModel } from '@shared/models/dashboard'
import React, { useState } from 'react'

interface Props {
  dashboards: Array<DashboardModel>
  initialDashboardId?: number
  disabled?: boolean
  add: (dashboardId?: number) => void
}

const useStyles = makeStyles(() => ({
  dashboards: {
    minWidth: 250
  }
}))

export const AddToDashboard: React.FC<Props> = ({
  dashboards,
  initialDashboardId,
  disabled,
  add
}) => {
  const classes = useStyles()
  const [dashboardId, setDashboardId] = useState(initialDashboardId || 0)

  return (
    <>
      {!!dashboards.length && (
        <Grid item>
          <Select
            value={dashboardId}
            onChange={(e) => setDashboardId(e.target.value as number)}
            className={classes.dashboards}
          >
            {dashboards.map((dashboard, index) => (
              <MenuItem key={index} value={dashboard.id}>
                <Typography variant="subtitle1">{dashboard.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item>
        <Button
          primary
          icon={<ArrowForward />}
          disabled={disabled}
          onClick={() => add(dashboardId)}
        >
          Add to dashboard
        </Button>
      </Grid>
    </>
  )
}
