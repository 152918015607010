import { Box, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { ErrorButton } from '@root/components/error-button'
import { OutlineButton } from '@root/components/outline-button'
import { theme } from '@root/constants/theme'
import React, { useEffect } from 'react'
import { closeDialog } from '../../store/actions'
import { Field } from './components/field'
import {
  addMerchant,
  addRole,
  init,
  removeMerchant,
  removeRole,
  reset,
  save,
  setStatus
} from './store/actions'
import {
  useAvailableMerchants,
  useAvailableRoles,
  useCanSave,
  useCanSetStatus,
  useErrors,
  useMerchants,
  useOpen
} from './store/selectors'
import { useUserDialogStore } from './store/store'

const useStyles = makeStyles((muiTheme) => ({
  label: {
    fontSize: 13,
    fontWeight: 500,
    marginBottom: muiTheme.spacing(0.5)
  },
  textField: {
    padding: muiTheme.spacing(1.5),
    background: theme.colors.veryLightBlue,
    borderRadius: 4,
    fontSize: 18,
    fontWeight: 500
  },
  input: {
    padding: 0
  },
  tag: {
    padding: muiTheme.spacing(1),
    background: 'rgba(0, 105, 255, 0.14)',
    borderRadius: 4,
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.blue(),
    cursor: 'pointer'
  }
}))

export const UserDialog: React.FC = () => {
  const classes = useStyles()
  const errors = useErrors()
  const canSetStatus = useCanSetStatus()
  const canSave = useCanSave()
  const open = useOpen()
  const availableRoles = useAvailableRoles()
  const availableMerchants = useAvailableMerchants()
  const merchants = useMerchants()
  const [roles, status] = useUserDialogStore((state) => [
    state.roles,
    state.status
  ])

  useEffect(() => {
    open && init()

    return () => {
      !open && reset()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      title=""
      content={
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field field="email" errors={errors} required autoFocus />
            </Grid>
            <Grid item xs={12}>
              <Field field="singleSignOn" errors={errors} />
            </Grid>
          </Grid>
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Roles</Typography>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {roles.map((role, index) => (
                  <Grid key={index} item>
                    <span
                      className={classes.tag}
                      onClick={() => removeRole(role)}
                    >
                      {role.name}
                    </span>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <Dropdown
                    button={
                      <OutlineButton
                        primary
                        icon={<Add />}
                        iconRight
                        disabled={!availableRoles.length}
                      >
                        Add role
                      </OutlineButton>
                    }
                  >
                    {availableRoles.map((role, index) => (
                      <MenuItem key={index} onClick={() => addRole(role)}>
                        <Typography variant="inherit">{role.name}</Typography>
                      </MenuItem>
                    ))}
                  </Dropdown>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Merchant</Typography>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {merchants.map((merchant, index) => (
                  <Grid key={index} item>
                    <span
                      className={classes.tag}
                      onClick={() => removeMerchant(merchant)}
                    >
                      {merchant.name}
                    </span>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <Dropdown
                    button={
                      <OutlineButton
                        primary
                        icon={<Add />}
                        iconRight
                        disabled={!availableMerchants.length}
                      >
                        Add merchant
                      </OutlineButton>
                    }
                  >
                    {availableMerchants.map((merchant, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => addMerchant(merchant)}
                      >
                        <Typography variant="inherit">
                          {merchant.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Dropdown>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      actions={
        <>
          <ErrorButton disabled={!canSetStatus} onClick={setStatus}>
            {status === 'active' ? 'Block user' : 'Unblock user'}
          </ErrorButton>
          <Button primary disabled={!canSave} onClick={save}>
            Save changes
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
